@import url(../../styles/icons/all/all.css);
.outLineDangerColor {
  background-color: white !important;
  border: 1px solid #ff818d !important;
  color: #ff818d !important; }

.dangerColor {
  background-color: #ff818d !important;
  border-color: #ff818d !important;
  color: black !important; }

.halfDangerColor {
  background-color: #ffa581 !important;
  border-color: #ffa581 !important;
  color: black !important; }

.outLineSafeColor {
  background-color: white !important;
  border: 1px solid #bbf2c8 !important;
  color: black !important; }

.safeColor {
  background-color: #bbf2c8 !important;
  border-color: #bbf2c8 !important;
  color: black !important; }

.defaultColor {
  background-color: #1d80df !important;
  border-color: #1d80df !important;
  color: white !important; }

.defaultColorSecendery {
  background-color: white !important;
  border-color: #1d80df !important;
  color: #1d80df !important; }

h1 {
  font-size: 1.5rem; }

* {
  padding: 0;
  margin: 0;
  font-family: 'Noto Sans Hebrew', sans-serif;
  transition: all 0.2s; }
  *::-webkit-scrollbar {
    width: 10px;
    height: 10px; }
    *::-webkit-scrollbar-track {
      background: #ffffff; }
    *::-webkit-scrollbar-thumb {
      height: 10px;
      background: #afafaf;
      border-radius: 3px; }
      *::-webkit-scrollbar-thumb:hover {
        background: #757575; }
  *:disabled {
    cursor: not-allowed; }

.enFont {
  font-family: 'Fredoka One', cursive; }

html,
body,
.app {
  overflow: hidden;
  height: 100vh; }

.app {
  background-color: #f8fcff;
  margin: auto;
  height: 100vh;
  width: 100vw;
  display: grid;
  justify-content: center;
  grid-template-rows: 100px calc(100vh - 150px);
  grid-template-columns: 225px calc(100vw - 225px);
  direction: rtl; }
  .app .logo {
    height: calc(100% - 4rem);
    width: 100%;
    background: #e5f3fe;
    padding: 2rem 1rem;
    object-fit: contain;
    object-position: center; }

input[type='file'] {
  display: none; }

input {
  outline: none;
  border: none; }

img {
  object-fit: contain;
  object-position: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield; }

button {
  border: 0;
  outline: 0;
  background: none;
  font-size: 1rem; }

.copyRight {
  position: absolute;
  pointer-events: none;
  top: calc(100vh - 1rem);
  left: 0;
  z-index: 1000;
  font-size: 0.8rem; }

.absolute {
  position: absolute; }

.center {
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center; }

.clickable {
  user-select: none; }
  .clickable:hover {
    opacity: 0.7; }
  .clickable:active {
    opacity: 0.5; }

.clickable,
.clickableHalf {
  cursor: pointer; }

.dropDown {
  position: relative; }
  .dropDown .options {
    z-index: 100;
    background-color: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 0;
    border-radius: 0 0 1rem 1rem; }
  .dropDown.active .options {
    height: 300%; }

.flex {
  display: flex; }

.flexColumn {
  display: flex;
  flex-direction: column; }

.flexScroll {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: auto;
  cursor: grab; }
  .flexScroll:active {
    cursor: grabbing; }

.full {
  height: 100%;
  width: 100%; }

.fullPage {
  width: 100vw;
  height: 100vh; }

.gridOne {
  display: grid;
  align-items: center; }
  .gridOne > * {
    grid-area: 1/1; }

.ltr {
  direction: ltr !important; }

.rtl {
  direction: rtl !important; }

.relative {
  position: relative; }

.submitBtn {
  direction: ltr;
  padding: 1rem;
  border-radius: 1rem;
  color: white !important;
  text-align: center;
  width: max-content; }

.unclickable {
  user-select: none; }

.widthShrink {
  position: relative;
  overflow: hidden;
  transform: translate(-200%);
  opacity: 0;
  width: 0px !important; }

.categoriesScroll {
  height: 120px;
  width: 700px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);
  background-color: #fff; }
  .categoriesScroll .header {
    background-color: #eef7f5;
    width: 150px;
    color: #18276f; }
  .categoriesScroll .tools.scroll.categories {
    width: 100%;
    gap: 10px;
    align-items: center; }
    .categoriesScroll .tools.scroll.categories::-webkit-scrollbar {
      width: 10px;
      height: 5px; }
      .categoriesScroll .tools.scroll.categories::-webkit-scrollbar-track {
        background: #ffffff; }
      .categoriesScroll .tools.scroll.categories::-webkit-scrollbar-thumb {
        height: 10px;
        background: #18276f;
        border-radius: 3px; }
        .categoriesScroll .tools.scroll.categories::-webkit-scrollbar-thumb:hover {
          background: #2e41a3; }

.categoryCard {
  padding: 5px;
  min-width: max-content;
  min-height: 30px;
  overflow: hidden;
  align-items: center;
  gap: 10px; }
  .categoryCard .imgWrapper {
    border-radius: 5px; }
    .categoryCard .imgWrapper .image {
      min-width: 20px;
      padding: 7px 3px;
      width: 20px;
      height: 20px;
      padding: 10px;
      box-shadow: 1px 1px 5px 0 #023c8b47;
      background: none;
      transform: translateX(-100px);
      filter: drop-shadow(100px 0 #18276f); }
    .categoryCard .imgWrapper .bg {
      border-radius: 7px;
      height: 100%;
      width: 100%;
      background-color: #f5fdff; }
  .categoryCard .name {
    color: #18276f;
    white-space: nowrap;
    font-size: 0.8rem; }
  .categoryCard.selected .imgWrapper {
    transform: scale(1.1); }
    .categoryCard.selected .imgWrapper .bg {
      background: linear-gradient(45deg, #34a5f4 0%, #9fe7d1 100%); }

.layout.fileWrapper {
  width: 100%;
  height: 100%; }

.fullPopup {
  background-color: rgba(0, 0, 0, 0.5); }
  .fullPopup,
  .fullPopup .background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw; }
  .fullPopup .content {
    background: linear-gradient(315deg, #e5f3fe 0%, #f8fefa 100%);
    align-items: flex-start;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    width: max-content;
    max-width: 80%;
    max-height: 80%;
    overflow: hidden;
    overflow-y: auto; }
    .fullPopup .content .icon {
      position: absolute; }
      .fullPopup .content .icon .iconBtn {
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
        background: linear-gradient(315deg, #e5f3fe 0%, #f8fefa 100%);
        background-color: #ff818d;
        padding: 1rem;
        position: relative;
        top: -3rem;
        color: black;
        height: 20px;
        width: 20px;
        border-radius: 0.5rem; }
        .fullPopup .content .icon .iconBtn:hover {
          transform: rotate(90deg);
          box-shadow: 2px -2px 5px rgba(0, 0, 0, 0.5); }
    .fullPopup .content > .children > * {
      padding: 3rem 4rem; }
  .fullPopup,
  .fullPopup .content,
  .fullPopup .iconBtn {
    z-index: 100; }
  .fullPopup .background {
    z-index: 99; }
  .fullPopup.close {
    display: none; }
  @media only screen and (max-width: 1440px) {
    .fullPopup .content {
      max-width: 90%; } }
  @media only screen and (max-width: 425px) {
    .fullPopup .content {
      max-width: calc(100% - 1rem); } }

.gridProducts {
  margin-top: 3rem; }
  .gridProducts .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    user-select: none; }
  .gridProducts .products {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); }
    .gridProducts .products .productCard {
      margin: 0.5rem; }
    .gridProducts .products .productCardSmall {
      margin: 0.5rem; }

.messageWithBtns {
  width: fit-content; }
  .messageWithBtns .text {
    width: fit-content; }
  .messageWithBtns .btns {
    gap: 1rem; }
    .messageWithBtns .btns .iconBtn {
      padding: 0.5rem 1rem;
      margin-top: 1.5rem;
      border-radius: 0.5rem;
      box-shadow: 2px 2px 5px #aaa;
      color: black; }

.page {
  justify-content: flex-end; }
  .page .content {
    width: 100%;
    display: flex;
    justify-content: flex-start; }
    .page .content > * {
      width: inherit; }

.layout.productPopup.browser {
  gap: 4rem;
  padding: 0 !important; }
  .layout.productPopup.browser .sectionOne .images {
    gap: 0.5rem; }
    .layout.productPopup.browser .sectionOne .images .mainImg,
    .layout.productPopup.browser .sectionOne .images .side .sideImg {
      background-color: white;
      border-radius: 0.5rem;
      object-fit: contain;
      object-position: center; }
    .layout.productPopup.browser .sectionOne .images .mainImg {
      padding: 1rem;
      width: 600px;
      height: 400px; }
    .layout.productPopup.browser .sectionOne .images .side {
      gap: 0.5rem;
      overflow: hidden;
      overflow-x: auto;
      width: calc(600px + 2rem); }
      .layout.productPopup.browser .sectionOne .images .side .sideImg {
        width: calc(100px - 0.1rem);
        height: 100px; }
  .layout.productPopup.browser .sectionOne .details {
    width: 100%;
    height: calc(400px + 100px - 0.7rem);
    padding: 1rem 2rem 0.5rem 1rem;
    gap: 1rem; }
    .layout.productPopup.browser .sectionOne .details .category {
      font-size: 0.6rem; }
    .layout.productPopup.browser .sectionOne .details .header {
      width: 100%;
      justify-content: space-between;
      color: #209cff;
      font-weight: bold; }
      .layout.productPopup.browser .sectionOne .details .header .rate {
        position: relative;
        font-size: 1.2rem;
        top: 0.2rem;
        margin-right: 1rem; }
      .layout.productPopup.browser .sectionOne .details .header .name {
        font-size: 1.5rem; }
    .layout.productPopup.browser .sectionOne .details .description {
      font-size: 0.8rem; }
    .layout.productPopup.browser .sectionOne .details .colors {
      gap: 0.5rem; }
      .layout.productPopup.browser .sectionOne .details .colors .color__1, .layout.productPopup.browser .sectionOne .details .colors .color__2 {
        display: block;
        height: 25px;
        width: 25px;
        border-radius: 50%; }
      .layout.productPopup.browser .sectionOne .details .colors .color__1 {
        background-color: #c4c4c4; }
      .layout.productPopup.browser .sectionOne .details .colors .color__2 {
        background-color: #fdabab; }
    .layout.productPopup.browser .sectionOne .details .closeLocation {
      width: 100%;
      margin: auto;
      margin-bottom: 0; }
      .layout.productPopup.browser .sectionOne .details .closeLocation .flex {
        gap: 10rem;
        justify-content: space-between;
        align-items: center; }
        .layout.productPopup.browser .sectionOne .details .closeLocation .flex .iconBtn {
          background-color: #e5f3fe;
          color: #209cff;
          padding: 5px;
          font-size: 0.8rem;
          border-radius: 5px;
          flex-direction: row-reverse;
          width: max-content; }
      .layout.productPopup.browser .sectionOne .details .closeLocation .storeCard {
        width: max-content;
        box-shadow: none;
        background: none;
        padding: 0;
        margin: 0;
        margin-top: 1rem; }
        .layout.productPopup.browser .sectionOne .details .closeLocation .storeCard .storeLogo {
          margin: 0; }
        .layout.productPopup.browser .sectionOne .details .closeLocation .storeCard .btn {
          display: none; }
    .layout.productPopup.browser .sectionOne .details .addProductBtn {
      align-self: flex-start;
      padding: 0.8rem 4rem; }
  .layout.productPopup.browser .mapTop {
    margin: 1rem 5rem;
    justify-content: space-between; }
    .layout.productPopup.browser .mapTop .title {
      font-size: 1.5rem;
      font-weight: bold;
      color: #555; }
    .layout.productPopup.browser .mapTop .iconBtn {
      padding: 10px;
      border: 0.5rem;
      background-color: #e9deff;
      border-radius: 0.5rem; }
      .layout.productPopup.browser .mapTop .iconBtn .text {
        font-weight: bold;
        color: #4221f9; }
  .layout.productPopup.browser .bigMap .icon {
    position: absolute;
    left: 93vw;
    top: 5vh; }
    .layout.productPopup.browser .bigMap .icon .iconBtn {
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
      background: linear-gradient(315deg, #e5f3fe 0%, #f8fefa 100%);
      background-color: #ff818d;
      padding: 1rem;
      position: relative;
      top: -3rem;
      color: black;
      height: 20px;
      width: 20px;
      border-radius: 0.5rem; }
      .layout.productPopup.browser .bigMap .icon .iconBtn:hover {
        transform: rotate(90deg);
        box-shadow: 2px -2px 5px rgba(0, 0, 0, 0.5); }
  .layout.productPopup.browser .bigMap .mapWrapper {
    z-index: 101;
    position: absolute;
    left: 5vw;
    top: 5vh;
    height: 90vh;
    width: 90vw;
    border-radius: 1rem; }
  .layout.productPopup.browser .smallMap {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem; }
    .layout.productPopup.browser .smallMap .mapWrapper {
      margin: 1rem;
      align-self: center;
      height: 400px;
      width: calc(100% - 10rem);
      box-shadow: 0 0 5px #aaa;
      border-radius: 1rem;
      overflow: visible;
      margin-bottom: 20px; }
      .layout.productPopup.browser .smallMap .mapWrapper .icons {
        bottom: 170px; }
        .layout.productPopup.browser .smallMap .mapWrapper .icons .dropDwon {
          display: none; }
        .layout.productPopup.browser .smallMap .mapWrapper .icons.close {
          transform: translateY(0); }
      .layout.productPopup.browser .smallMap .mapWrapper .downStores {
        background: none;
        box-shadow: none;
        transform: translateY(40px); }
  @media only screen and (max-width: 1440px) {
    .layout.productPopup.browser .sectionOne {
      flex-direction: column; }
      .layout.productPopup.browser .sectionOne .details {
        height: auto; }
        .layout.productPopup.browser .sectionOne .details .description {
          min-height: 5rem; }
    .layout.productPopup.browser .smallMap .mapWrapper {
      width: calc(100% - 1rem); } }
  @media only screen and (max-width: 1024px) {
    .layout.productPopup.browser {
      padding: 0.5rem !important;
      justify-content: center; }
      .layout.productPopup.browser .sectionOne .images {
        align-items: center;
        justify-content: center; }
        .layout.productPopup.browser .sectionOne .images .mainImg {
          min-width: 100px;
          width: calc(80% - 1rem); }
        .layout.productPopup.browser .sectionOne .images .side {
          justify-content: center; } }
  @media only screen and (max-width: 768px) {
    .layout.productPopup.browser .sectionOne .details {
      padding: 0 !important; }
      .layout.productPopup.browser .sectionOne .details .header {
        flex-direction: column;
        align-items: flex-start; }
    .layout.productPopup.browser .mapTop {
      margin: 0 !important; } }

.layout.productPopup.mobile {
  height: 100vh;
  margin-top: 0.5rem;
  overflow-x: hidden;
  align-items: flex-start; }
  .layout.productPopup.mobile .sectionOne {
    flex-direction: column; }
    .layout.productPopup.mobile .sectionOne .images {
      align-items: center;
      width: 100vw; }
      .layout.productPopup.mobile .sectionOne .images .mainImg {
        justify-self: center;
        height: 250px;
        width: 100vw; }
      .layout.productPopup.mobile .sectionOne .images .side {
        padding-left: 1rem;
        justify-self: flex-end;
        flex-direction: column;
        gap: 5px; }
        .layout.productPopup.mobile .sectionOne .images .side .sideImg {
          width: 6px;
          height: 6px;
          object-position: -99999px 99999px;
          background-color: #c4c4c4;
          border: 1px solid #c4c4c4;
          border-radius: 50%; }
          .layout.productPopup.mobile .sectionOne .images .side .sideImg.active {
            background-color: white;
            border-color: #707070; }
    .layout.productPopup.mobile .sectionOne .details {
      width: calc(100vw - 2rem);
      padding: 1rem; }
      .layout.productPopup.mobile .sectionOne .details .category {
        color: #555555;
        font-size: 0.7rem; }
      .layout.productPopup.mobile .sectionOne .details .header {
        color: #209cff;
        font-weight: bold;
        justify-content: space-between; }
      .layout.productPopup.mobile .sectionOne .details .companyName {
        color: #6c6c6c;
        font-weight: bold;
        margin-bottom: 1rem; }
      .layout.productPopup.mobile .sectionOne .details .description {
        color: #454545;
        font-size: 0.8rem;
        margin-bottom: 1rem; }
      .layout.productPopup.mobile .sectionOne .details .closeLocation {
        width: 100%;
        margin: auto;
        margin-bottom: 0; }
        .layout.productPopup.mobile .sectionOne .details .closeLocation .flex {
          justify-content: space-between;
          align-items: center; }
          .layout.productPopup.mobile .sectionOne .details .closeLocation .flex .text {
            white-space: nowrap; }
          .layout.productPopup.mobile .sectionOne .details .closeLocation .flex .iconBtn {
            white-space: nowrap;
            background-color: #e5f3fe;
            color: #209cff;
            padding: 5px;
            font-size: 0.8rem;
            border-radius: 5px;
            flex-direction: row-reverse;
            width: max-content; }
        .layout.productPopup.mobile .sectionOne .details .closeLocation .storeCard {
          width: max-content;
          box-shadow: none;
          background: none;
          padding: 0;
          margin: 0;
          margin-top: 1rem; }
          .layout.productPopup.mobile .sectionOne .details .closeLocation .storeCard .storeLogo {
            margin: 0; }
          .layout.productPopup.mobile .sectionOne .details .closeLocation .storeCard .btn {
            display: none; }
      .layout.productPopup.mobile .sectionOne .details .addProductBtn {
        margin-top: 1rem;
        width: 100%;
        background: linear-gradient(90deg, #3e44e0 0%, #3698ff 100%); }
  .layout.productPopup.mobile .divider {
    width: 100vw;
    gap: 1rem;
    color: #9d9d9d;
    font-size: 0.8rem;
    margin: 2rem 0; }
    .layout.productPopup.mobile .divider .hr {
      width: 30%;
      border-color: #9d9d9d; }
  .layout.productPopup.mobile .mapContainer .mapWrapper {
    width: 100vw;
    height: 250px; }
    .layout.productPopup.mobile .mapContainer .mapWrapper .flexScroll {
      width: 100%; }
  .layout.productPopup.mobile .mapContainer .storeOnMapBtn {
    margin: 1rem;
    white-space: nowrap;
    background-color: #e5f3fe;
    color: #209cff;
    padding: 5px;
    font-size: 0.8rem;
    border-radius: 5px;
    flex-direction: row-reverse;
    width: max-content; }

.tools.producer {
  gap: 0.5rem;
  margin: 1rem; }
  .tools.producer .title {
    font-size: 1.3rem; }
  .tools.producer .logoWrapper {
    gap: 1rem; }
    .tools.producer .logoWrapper .logo {
      padding: 0;
      border-radius: 0.5rem;
      width: 50px;
      height: 50px;
      background-color: white; }
    .tools.producer .logoWrapper .details {
      height: max-content;
      padding: 0; }
      .tools.producer .logoWrapper .details .iconBtn {
        direction: ltr;
        white-space: nowrap;
        color: #afafaf; }
        .tools.producer .logoWrapper .details .iconBtn__icon {
          color: #777;
          font-size: 1.4rem;
          position: relative;
          top: 1.5px; }

.tools.track {
  margin: 1rem; }
  .tools.track .title {
    font-size: 1.3rem; }
  .tools.track .text {
    margin: 0 0 1rem 1rem;
    white-space: nowrap; }

.productsScroll {
  margin-top: 3rem; }
  .productsScroll .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    width: calc(100% - 2rem);
    user-select: none; }
    .productsScroll .header .iconBtn {
      background-color: #e5f3fe;
      color: #68c4e5;
      font-weight: bold;
      padding: 5px;
      border-radius: 5px;
      flex-direction: row-reverse; }
  .productsScroll .products .bg {
    pointer-events: none;
    height: 100%; }
    .productsScroll .products .bg.left {
      box-shadow: inset 30px 0 20px -20px #f8fcff; }
    .productsScroll .products .bg.right {
      box-shadow: inset -30px 0 20px -20px #f8fcff; }
  .productsScroll .products .leftBtn,
  .productsScroll .products .rightBtn {
    height: 50px;
    width: 50px;
    color: #1d2772;
    font-size: 2rem;
    background-color: white;
    align-self: center;
    box-shadow: 1px 1px 4px #aaa;
    border-radius: 0.5rem;
    z-index: 1;
    margin: 0.5rem; }
    .productsScroll .products .leftBtn:hover,
    .productsScroll .products .rightBtn:hover {
      opacity: 0.5;
      cursor: pointer; }
  .productsScroll .products .leftBtn {
    justify-self: flex-end; }

.topCategoriesScroll {
  margin-top: 3rem; }
  .topCategoriesScroll .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    width: calc(100% - 2rem);
    user-select: none; }
    .topCategoriesScroll .header .iconBtn {
      background-color: #e5f3fe;
      color: #68c4e5;
      font-weight: bold;
      padding: 5px;
      border-radius: 5px;
      flex-direction: row-reverse; }
  .topCategoriesScroll .products .bg {
    pointer-events: none;
    height: 100%; }
    .topCategoriesScroll .products .bg.left {
      box-shadow: inset 30px 0 20px -20px #f8fcff; }
    .topCategoriesScroll .products .bg.right {
      box-shadow: inset -30px 0 20px -20px #f8fcff; }
  .topCategoriesScroll .products .leftBtn,
  .topCategoriesScroll .products .rightBtn {
    height: 50px;
    width: 50px;
    color: #1d2772;
    font-size: 2rem;
    background-color: white;
    align-self: center;
    box-shadow: 1px 1px 4px #aaa;
    border-radius: 0.5rem;
    z-index: 1;
    margin: 0.5rem; }
    .topCategoriesScroll .products .leftBtn:hover,
    .topCategoriesScroll .products .rightBtn:hover {
      opacity: 0.5;
      cursor: pointer; }
  .topCategoriesScroll .products .leftBtn {
    justify-self: flex-end; }

.tools.cart .sideCart {
  position: absolute;
  left: 0;
  z-index: 50;
  height: calc(100% - 100px - 1rem);
  width: 500px;
  margin-right: 2rem;
  border-radius: 0 1rem 1rem 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  background: linear-gradient(270deg, #d7e5ed 0%, #e9efed 100%); }
  .tools.cart .sideCart .flex {
    overflow: hidden;
    overflow-y: auto;
    max-height: 100%; }
  .tools.cart .sideCart .botSideCart {
    margin-top: auto; }

.tools.cart .closeBtn {
  position: absolute;
  left: 0px;
  top: 50vh;
  z-index: 49;
  background: linear-gradient(90deg, #34a5f4 0%, #9fe7d1 100%);
  color: black;
  padding: 1rem 0.5rem 1rem 500px;
  border-radius: 0 2rem 2rem 0; }
  .tools.cart .closeBtn:hover {
    opacity: 1;
    padding: 1rem 1rem 1rem calc(500px + 1rem); }
  .tools.cart .closeBtn.widthShrink {
    transform: translate(-500px);
    opacity: 1;
    width: fit-content !important; }
    .tools.cart .closeBtn.widthShrink .iconBtn__icon {
      transform: rotate(180deg); }

.topSideCart {
  padding: 2rem;
  justify-content: space-between;
  background: linear-gradient(270deg, #34a5f4 0%, #9fe7d1 100%);
  border-radius: 0 1rem 1rem 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
  z-index: 51; }
  .topSideCart .cartnum {
    color: white;
    gap: 0.5rem;
    font-size: 1.1rem; }
    .topSideCart .cartnum .iconBtn__icon .amount {
      font-size: 0.6rem;
      left: calc(100% - 0.6rem); }
  .topSideCart .trash {
    color: #ff818d;
    gap: 0.2rem;
    font-size: 0.8rem;
    font-weight: bold; }
  .topSideCart .fullPopup {
    left: -0.5rem;
    top: calc(-100px - 0.5rem); }

.botSideCart {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: white;
  font-size: 1.3rem;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 0 1rem 1rem 0; }
  .botSideCart .iconBtn {
    border-radius: 0 1rem 1rem 0; }
  .botSideCart .calc {
    padding: 1rem;
    gap: 0.5rem;
    justify-content: center;
    color: #68c4e5; }

.sideCartProduct {
  padding: 1rem;
  gap: 0.5rem; }
  .sideCartProduct [type='checkbox']:hover {
    background-color: #e9efed; }
  .sideCartProduct [type='checkbox']:checked {
    background-color: #34a5f4; }
    .sideCartProduct [type='checkbox']:checked:hover {
      transition: all 0.2s;
      background-color: #ff818d; }
  .sideCartProduct img,
  .sideCartProduct .ditails {
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid #34a5f4;
    background-color: white;
    border-radius: 1rem;
    overflow: hidden; }
  .sideCartProduct img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    object-position: center; }
    .sideCartProduct img:hover {
      cursor: pointer; }
  .sideCartProduct .ditails {
    color: #1d2772;
    width: 300px; }
    .sideCartProduct .ditails .company {
      font-size: 0.7rem;
      color: #aaa; }

.tools.twoBtnSwitch {
  width: max-content;
  background-color: #f5fdff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  border-radius: 5px;
  box-shadow: 0 1px 8.5px 0 rgba(0, 0, 0, 0.09);
  background-color: #f5fdff; }
  .tools.twoBtnSwitch .btnBg {
    margin: 5px;
    height: 35px;
    border-radius: 5px; }
  .tools.twoBtnSwitch .btnBg {
    z-index: 1;
    position: relative;
    width: calc(50% - 5px);
    height: 80%;
    border-radius: 5px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(180deg, #209cff 0%, #331dd9 100%); }
    .tools.twoBtnSwitch .btnBg.active {
      transform: translateX(-100%); }
  .tools.twoBtnSwitch .btns {
    z-index: 2; }
    .tools.twoBtnSwitch .btns .iconBtn {
      opacity: 1;
      overflow: hidden;
      padding: 20px 50px;
      color: #53576a; }
    .tools.twoBtnSwitch .btns .active {
      font-weight: bold;
      color: white; }

.layout.botBar {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #fafafa;
  color: #4d4d4d;
  width: 100%;
  justify-content: space-around;
  align-items: flex-start;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5); }
  .layout.botBar .iconBtn {
    width: 20%;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start; }
    .layout.botBar .iconBtn__icon {
      padding: 0.5rem 0 0 0;
      font-size: 1.5rem; }
    .layout.botBar .iconBtn .text {
      padding: 0 0 0.5rem 0;
      font-size: 0.8rem; }
    .layout.botBar .iconBtn.active {
      opacity: 1;
      font-weight: bold;
      color: #4038ad;
      background: #e5f3fe;
      background: linear-gradient(45deg, #f2f7fc 0%, #f1f8fc 100%); }
    .layout.botBar .iconBtn__active {
      position: absolute;
      background: linear-gradient(135deg, #3698ff 0%, #3e44e0 100%);
      border-radius: 3px;
      height: 5px;
      width: 20%; }

.layout.twoRoudsProducts {
  background-image: linear-gradient(90deg, #f8fffa, #e5f3ff); }
  .layout.twoRoudsProducts .header {
    padding: 1.5rem 1rem; }
  .layout.twoRoudsProducts .products {
    justify-content: center; }
    .layout.twoRoudsProducts .products,
    .layout.twoRoudsProducts .products .productsRight,
    .layout.twoRoudsProducts .products .productsLeft {
      gap: 0.8rem; }

.tools.iconBtn {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  display: flex;
  gap: 0.2rem; }
  .tools.iconBtn__icon ::before {
    position: relative;
    top: 3px; }
  .tools.iconBtn .amount {
    width: fit-content;
    padding: 0 0.35rem;
    font-size: 0.8rem;
    background-color: #ff818d;
    color: white;
    border-radius: 0.65rem;
    border: 2px solid #4038ad;
    align-self: flex-start;
    position: relative;
    top: -1rem;
    left: calc(100% - 0.3rem); }
  .tools.iconBtn .image {
    height: inherit;
    width: inherit;
    object-fit: contain;
    object-position: center; }
  .tools.iconBtn.unclickable {
    opacity: 0.5;
    pointer-events: none; }

.tools.amountManeger {
  gap: 0.5rem; }
  .tools.amountManeger input[type='number'] {
    width: 2rem;
    text-align: center;
    font-size: 1.2rem;
    background: none; }
  .tools.amountManeger .iconBtn {
    font-size: 1.5rem;
    border-radius: 50%;
    padding: 0.1rem; }
  .tools.amountManeger .plus:hover {
    background-color: #bbf2c8; }
  .tools.amountManeger .minus:hover {
    background-color: #ff818d; }

.tools.barGraph {
  width: 100%;
  margin: 0 1rem; }

.tools.bar {
  align-items: center; }
  .tools.bar .text {
    white-space: nowrap;
    width: 5.5rem; }
  .tools.bar .value {
    margin: 1rem;
    width: 100%;
    height: 15px; }
    .tools.bar .value .fill {
      background-color: #afafaf; }
    .tools.bar .value .nofill {
      background-color: #dadada; }
  .tools.bar .amount {
    text-align: center;
    width: 3rem; }

.tools.btn {
  direction: ltr;
  padding: 1rem;
  border-radius: 0.5rem;
  background: linear-gradient(90deg, #3698ff 0%, #3e44e0 100%);
  color: white;
  text-align: center; }
  .tools.btn__text {
    width: max-content; }

.tools.categoriesPopup {
  height: 600px;
  width: 800px;
  justify-content: space-between; }
  .tools.categoriesPopup .iconBtn {
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
    background-color: #bbf2c8;
    color: black;
    direction: ltr;
    gap: 0.5rem;
    flex-direction: column-reverse; }
    .tools.categoriesPopup .iconBtn .image {
      width: 50px;
      height: 50px; }
  .tools.categoriesPopup .top .parents {
    display: flex;
    overflow-x: auto; }
    .tools.categoriesPopup .top .parents .iconBtn {
      background-color: #8cd8d8; }
  .tools.categoriesPopup .childrens {
    height: 100%;
    display: grid;
    align-items: flex-start;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); }
  .tools.categoriesPopup .bot .btns {
    margin-top: 1rem;
    align-self: center; }

[type='checkbox'] {
  width: 2rem;
  height: 2rem;
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 0;
  border-radius: 50%;
  background-color: #ffffff;
  transition: background 300ms;
  cursor: pointer; }

/* Pseudo element for check styling */
[type='checkbox']::before {
  content: '';
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain; }

/* Checked */
[type='checkbox']:checked {
  background-color: currentcolor; }

[type='checkbox']:checked::before {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E"); }

/* Disabled */
[type='checkbox']:disabled {
  background-color: #ccd3d8;
  opacity: 0.84;
  cursor: not-allowed; }

/* IE */
[type='checkbox']::-ms-check {
  content: '';
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #ccd3d8; }

[type='checkbox']:checked::-ms-check {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E"); }

.tools.dragPopup {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0; }
  .tools.dragPopup.close {
    opacity: 0;
    pointer-events: none; }
  .tools.dragPopup .contentWrapper {
    height: 100vh;
    background-color: white;
    align-items: flex-start;
    overflow: hidden; }
    .tools.dragPopup .contentWrapper .dragSignWrapper {
      width: 100vw; }
      .tools.dragPopup .contentWrapper .dragSignWrapper .dragSign {
        height: 6px;
        width: 40px;
        border-radius: 3px;
        margin: auto; }
      .tools.dragPopup .contentWrapper .dragSignWrapper .closeBtn {
        justify-self: flex-end;
        color: black;
        gap: 5px;
        margin: 10px 20px;
        font-size: 0.8rem; }

@keyframes bounce {
  0% {
    transform: scaleY(1); }
  20% {
    transform: translateY(-2%) scaleY(1.04); }
  50% {
    transform: scaleY(1); }
  70% {
    transform: translateY(-1%) scaleY(1.02); }
  90% {
    transform: scaleY(1); } }

.tools.hr {
  outline: 0;
  border: 0;
  border-top: solid 1px black; }

.tools.image {
  border: 0;
  outline: 0;
  resize: none;
  width: 100%;
  height: 4rem;
  padding: 1rem;
  background-color: #d7e5ed;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  overflow: hidden; }

.dangerBorderColor {
  border-color: #ff818d !important; }

.tools.imageDropdown__image {
  height: 50px;
  width: 50px;
  object-fit: contain;
  object-position: center;
  border: 2px solid #ff818d;
  background-color: white;
  border-radius: 0.5rem; }

.tools.input {
  border: 0;
  outline: 0;
  width: 100%;
  height: 50px;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  direction: rtl; }
  .tools.input:disabled {
    background-color: #9fe7d1; }

.inputLableWrapper {
  width: 100%; }
  .inputLableWrapper .tools.inputLable {
    width: 100%;
    flex-direction: column-reverse;
    align-items: flex-end; }
    .inputLableWrapper .tools.inputLable * {
      transition: all 0s; }
    .inputLableWrapper .tools.inputLable.error .input:required,
    .inputLableWrapper .tools.inputLable.error .select:required {
      border-color: #ff818d; }
    .inputLableWrapper .tools.inputLable.error .input:active:required ~ .text,
    .inputLableWrapper .tools.inputLable.error .input:focus:required ~ .text,
    .inputLableWrapper .tools.inputLable.error .select:active:required ~ .text,
    .inputLableWrapper .tools.inputLable.error .select:focus:required ~ .text,
    .inputLableWrapper .tools.inputLable.error .text.active {
      color: #ff818d;
      transform: translateY(50%) scale(0.8); }
    .inputLableWrapper .tools.inputLable .text {
      transform: translateY(170%);
      width: fit-content;
      background-color: white;
      padding: 0 0.5rem;
      transition: all 0.2s, border-color 0s;
      border-radius: 1rem;
      pointer-events: none; }
    .inputLableWrapper .tools.inputLable .input,
    .inputLableWrapper .tools.inputLable .select {
      width: calc(100% - 2rem);
      padding: 0 1rem;
      align-self: center;
      background-color: white;
      border: solid 2px #1d80df; }
    .inputLableWrapper .tools.inputLable .select {
      width: calc(100% + 4px);
      height: 54px;
      margin: 0; }
    .inputLableWrapper .tools.inputLable .input:active ~ .text,
    .inputLableWrapper .tools.inputLable .input:focus ~ .text,
    .inputLableWrapper .tools.inputLable .select:active ~ .text,
    .inputLableWrapper .tools.inputLable .select:focus ~ .text,
    .inputLableWrapper .tools.inputLable .text.active {
      color: #1d80df;
      transform: translateY(50%) scale(0.8); }
    .inputLableWrapper .tools.inputLable__errors {
      width: 100%;
      direction: rtl;
      display: flex;
      gap: 5px;
      margin-bottom: 0.5rem; }
      .inputLableWrapper .tools.inputLable__errors .error {
        z-index: 3;
        user-select: none;
        position: absolute;
        color: #ff818d; }

.tools.mapWrapper {
  overflow: hidden;
  user-select: none; }
  .tools.mapWrapper .googleMap {
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    overflow: hidden; }
    .tools.mapWrapper .googleMap * {
      transition: none; }
    .tools.mapWrapper .googleMap .icons {
      z-index: 2;
      position: relative;
      align-self: flex-end;
      bottom: 210px;
      right: 5px;
      gap: 10px;
      transition: all 0.5s;
      padding: 5px; }
      .tools.mapWrapper .googleMap .icons.close {
        transform: translateY(120px); }
      .tools.mapWrapper .googleMap .icons .iconBtn {
        box-shadow: 0 0 5px #aaa;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: white;
        color: #3698ff;
        transition: all 0.5s; }
        .tools.mapWrapper .googleMap .icons .iconBtn:hover {
          transform: rotate(20deg); }
        .tools.mapWrapper .googleMap .icons .iconBtn.active {
          color: white;
          background: linear-gradient(270deg, #3e44e0 -50%, #3698ff 100%);
          box-shadow: 0 0 5px #3698ff; }
      .tools.mapWrapper .googleMap .icons .ditails {
        box-shadow: 0 0 5px #aaa;
        border-radius: 2rem;
        overflow: hidden;
        gap: 1rem;
        background-color: white;
        color: #3698ff; }
        .tools.mapWrapper .googleMap .icons .ditails .directionDistance,
        .tools.mapWrapper .googleMap .icons .ditails .directionDuration {
          box-shadow: none;
          white-space: nowrap;
          width: max-content;
          gap: 0.5rem; }
          .tools.mapWrapper .googleMap .icons .ditails .directionDistance:hover,
          .tools.mapWrapper .googleMap .icons .ditails .directionDuration:hover {
            transform: none; }
        .tools.mapWrapper .googleMap .icons .ditails .directionDistance {
          border-radius: 0 50% 50% 0;
          padding-right: 1rem; }
        .tools.mapWrapper .googleMap .icons .ditails .directionDuration {
          border-radius: 50% 0 0 50%;
          padding-left: 1rem; }
    .tools.mapWrapper .googleMap .map {
      overflow: hidden;
      align-self: flex-start;
      user-select: none;
      height: calc(100% + 31px);
      width: 100%; }
      .tools.mapWrapper .googleMap .map .storeInfo {
        width: 50px;
        height: 50px;
        border-radius: 50% 50% 50% 0; }
        .tools.mapWrapper .googleMap .map .storeInfo .storeLogo {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          transform: rotate(45deg);
          color: white;
          font-size: 0.8rem; }
        .tools.mapWrapper .googleMap .map .storeInfo.me {
          background: linear-gradient(270deg, #3e44e0 -50%, #3698ff 100%); }
          .tools.mapWrapper .googleMap .map .storeInfo.me .storeLogo {
            font-size: 0.7rem; }
      .tools.mapWrapper .googleMap .map .gm-style-iw.gm-style-iw-c {
        padding: 0.2rem !important;
        border-radius: 50% 50% 50% 0;
        transform: rotate(-45deg); }
        .tools.mapWrapper .googleMap .map .gm-style-iw.gm-style-iw-c .gm-ui-hover-effect {
          display: none !important; }
      .tools.mapWrapper .googleMap .map .gm-style-iw-d {
        overflow: hidden !important; }
      .tools.mapWrapper .googleMap .map .gm-style .gm-style-iw-t::after {
        display: none; }
      .tools.mapWrapper .googleMap .map .gm-style-iw-tc::after {
        display: none; }
      .tools.mapWrapper .googleMap .map .direction {
        display: none; }
  .tools.mapWrapper .downStores {
    position: relative;
    z-index: 0;
    width: 100%;
    align-self: flex-end;
    justify-self: center;
    display: flex;
    min-height: 120px;
    background-color: rgba(255, 255, 255, 0.8);
    transition: all 0.5s;
    box-shadow: 0 0 5px #aaa;
    padding: 0 0.5rem;
    gap: 0; }
    .tools.mapWrapper .downStores.close {
      transform: translateY(100%);
      box-shadow: none; }
    .tools.mapWrapper .downStores .storeCard,
    .tools.mapWrapper .downStores .storeCard:hover,
    .tools.mapWrapper .downStores .storeCard:active {
      opacity: 1; }

.tools.option {
  border: 0;
  outline: 0; }

.tools.placeMap .googleMap {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  width: 70vw; }
  .tools.placeMap .googleMap * {
    transition: none; }
  .tools.placeMap .googleMap .search {
    margin-top: 1rem;
    position: absolute;
    z-index: 1;
    width: 400px;
    box-shadow: 0 0 5px black;
    background-color: white; }
    .tools.placeMap .googleMap .search hr {
      outline: none;
      border: none;
      border-bottom: 1px solid; }
    .tools.placeMap .googleMap .search input {
      width: calc(100% - 1rem);
      padding: 0.5rem; }
    .tools.placeMap .googleMap .search div {
      padding: 0.5rem; }
      .tools.placeMap .googleMap .search div strong {
        border-bottom: 1px solid; }
  .tools.placeMap .googleMap .map {
    overflow: hidden;
    align-self: flex-start;
    user-select: none;
    height: 100%;
    width: 100%; }
    .tools.placeMap .googleMap .map .gm-style-iw.gm-style-iw-c {
      padding: 0.2rem !important;
      border-radius: 50% 50% 50% 0;
      transform: rotate(-45deg); }
      .tools.placeMap .googleMap .map .gm-style-iw.gm-style-iw-c .gm-ui-hover-effect {
        display: none !important; }
    .tools.placeMap .googleMap .map .gm-style-iw-d {
      overflow: hidden !important; }
    .tools.placeMap .googleMap .map .gm-style .gm-style-iw-t::after {
      display: none; }
    .tools.placeMap .googleMap .map .gm-style-iw-tc::after {
      display: none; }
    .tools.placeMap .googleMap .map .direction {
      display: none; }

.tools.productCard {
  max-height: 400px;
  min-width: 260px;
  max-width: 260px;
  box-shadow: 1px 1px 4px #aaa;
  background-color: white;
  overflow: hidden; }
  .tools.productCard:hover {
    transform: scale(1.02);
    box-shadow: 2px 2px 5px #aaa; }
  .tools.productCard .details {
    overflow: hidden;
    height: 100%;
    justify-content: space-between; }
    .tools.productCard .details,
    .tools.productCard .details .prodDit,
    .tools.productCard .details .storeDit {
      display: flex;
      flex-direction: column; }
    .tools.productCard .details .top {
      align-items: flex-end;
      justify-items: flex-end; }
      .tools.productCard .details .top .image,
      .tools.productCard .details .top .logo {
        object-fit: contain;
        object-position: center;
        background-color: white; }
      .tools.productCard .details .top .imgBg {
        pointer-events: none;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1; }
        .tools.productCard .details .top .imgBg .color {
          justify-self: center;
          height: 100%;
          width: 300%; }
        .tools.productCard .details .top .imgBg .number {
          z-index: 2;
          justify-self: center;
          font-size: 70px;
          color: white;
          filter: drop-shadow(0 0 5px black); }
      .tools.productCard .details .top .image {
        padding: 1rem;
        height: 200px;
        width: calc(100% - 2rem); }
        .tools.productCard .details .top .image:hover {
          cursor: pointer; }
      .tools.productCard .details .top .logo {
        z-index: 1;
        height: 35px;
        width: 75px;
        padding: 10px;
        border-radius: 0 0.5rem 0 0; }
      .tools.productCard .details .top .like {
        z-index: 1;
        background-color: #1d2772;
        color: white;
        align-self: flex-start;
        margin: 1rem;
        border-radius: 0.5rem;
        height: 30px;
        width: 30px;
        font-size: 0.8rem; }
        .tools.productCard .details .top .like:hover {
          cursor: pointer;
          opacity: 0.5; }
      .tools.productCard .details .top .isSale {
        margin: 1.2rem;
        padding: 0.2rem 0.8rem;
        border-radius: 0.5rem;
        color: white;
        background: linear-gradient(90deg, #ff818d 0%, #ffa581 100%);
        align-self: flex-start;
        justify-self: flex-start; }
    .tools.productCard .details .bot {
      height: 140px; }
      .tools.productCard .details .bot .prodDit {
        align-self: flex-start;
        padding: 1rem;
        color: #1d2772; }
        .tools.productCard .details .bot .prodDit .name {
          width: 200px;
          max-height: 3em;
          overflow: hidden;
          font-size: 1.1rem; }
        .tools.productCard .details .bot .prodDit .company {
          color: #aaa;
          font-size: 0.9rem; }
      .tools.productCard .details .bot .storeDit {
        padding: 1rem;
        align-self: flex-end;
        color: #68c4e5; }
        .tools.productCard .details .bot .storeDit .prices {
          margin-top: 0.5rem;
          gap: 0.5rem;
          align-items: flex-end; }
          .tools.productCard .details .bot .storeDit .prices .price.sale {
            text-decoration: line-through;
            color: #ff818d;
            font-size: 0.8rem; }
          .tools.productCard .details .bot .storeDit .prices .salePrice {
            font-weight: bold; }
        .tools.productCard .details .bot .storeDit .rate {
          display: flex;
          justify-content: flex-end;
          direction: ltr; }
    .tools.productCard .details .toCart {
      position: relative;
      left: -75px;
      align-self: flex-end;
      justify-self: flex-end;
      height: 45px;
      border-radius: 0 1rem 0 0; }
      .tools.productCard .details .toCart.none {
        background-color: #e5f3fe; }
        .tools.productCard .details .toCart.none:hover {
          cursor: pointer;
          opacity: 0.5; }
      .tools.productCard .details .toCart.have {
        transform: translateX(70px);
        background-color: #3baaf5; }
        .tools.productCard .details .toCart.have .amountManeger .iconBtn {
          color: black; }
      .tools.productCard .details .toCart .amountManeger {
        padding: 1rem; }
  .tools.productCard.have {
    background-color: #e9f3ff; }
    .tools.productCard.have .details .imgBg {
      opacity: 1; }
      .tools.productCard.have .details .imgBg .color {
        background: linear-gradient(90deg, #bbf2c8 0%, #3698ff 100%);
        opacity: 0.7;
        position: relative;
        animation: mymove 10s;
        animation-iteration-count: infinite; }

@keyframes mymove {
  0% {
    left: 0; }
  25% {
    left: 100%; }
  50% {
    left: 0; }
  75% {
    left: -100%; }
  100% {
    left: 0; } }

.tools.productCardHome {
  max-height: 300px;
  min-width: 180px;
  max-width: 180px;
  border-radius: 1rem;
  box-shadow: 1px 1px 4px #aaa;
  background-color: white;
  overflow: hidden;
  cursor: pointer; }
  .tools.productCardHome:hover {
    transform: scale(1.02);
    box-shadow: 2px 2px 5px #aaa; }
  .tools.productCardHome.regularSize {
    max-height: 300px; }
    .tools.productCardHome.regularSize .image {
      height: 150px; }
  .tools.productCardHome.bigSize {
    max-height: 350px; }
    .tools.productCardHome.bigSize .image {
      height: 200px; }
  .tools.productCardHome.smallSize {
    max-height: 250px; }
    .tools.productCardHome.smallSize .image {
      height: 100px; }
  .tools.productCardHome .details {
    overflow: hidden;
    height: 100%;
    justify-content: space-between; }
    .tools.productCardHome .details,
    .tools.productCardHome .details .prodDit,
    .tools.productCardHome .details .storeDit {
      display: flex;
      flex-direction: column; }
    .tools.productCardHome .details .top {
      align-items: flex-end;
      justify-items: flex-end; }
      .tools.productCardHome .details .top .image,
      .tools.productCardHome .details .top .logo {
        object-fit: contain;
        object-position: center;
        background-color: white; }
      .tools.productCardHome .details .top .image {
        width: 100%; }
      .tools.productCardHome .details .top .logo {
        height: 20px;
        width: 40px;
        padding: 10px;
        border-radius: 0 0.5rem 0 0; }
      .tools.productCardHome .details .top .like {
        background-color: #1d2772;
        color: white;
        align-self: flex-start;
        margin: 0.5rem;
        border-radius: 50%;
        height: 25px;
        width: 25px;
        font-size: 0.8rem; }
        .tools.productCardHome .details .top .like:hover {
          cursor: pointer;
          opacity: 0.5; }
      .tools.productCardHome .details .top .isSale {
        margin: 1.2rem;
        padding: 0.2rem 0.8rem;
        border-radius: 0.5rem;
        color: white;
        background: linear-gradient(90deg, #ff818d 0%, #ffa581 100%);
        align-self: flex-start;
        justify-self: flex-start; }
    .tools.productCardHome .details .bot {
      height: 100px; }
      .tools.productCardHome .details .bot .prodDit {
        align-self: flex-start;
        padding: 0.5rem;
        color: #1d2772; }
        .tools.productCardHome .details .bot .prodDit .name {
          width: 100%;
          white-space: nowrap;
          max-height: 3em;
          overflow: hidden;
          font-weight: bold; }
        .tools.productCardHome .details .bot .prodDit .company {
          font-size: 0.8rem; }
      .tools.productCardHome .details .bot .storeDit {
        padding: 0.5rem;
        align-self: flex-end;
        color: #489cf8; }
        .tools.productCardHome .details .bot .storeDit .prices {
          margin-top: 0.5rem;
          gap: 0.5rem;
          align-items: flex-end; }
          .tools.productCardHome .details .bot .storeDit .prices .price.sale {
            text-decoration: line-through;
            color: #ff818d;
            font-size: 0.8rem; }
          .tools.productCardHome .details .bot .storeDit .prices .salePrice {
            font-weight: bold; }
        .tools.productCardHome .details .bot .storeDit .rate {
          font-size: 0.7rem;
          display: flex;
          justify-content: flex-end;
          direction: ltr; }

.tools.productCardSmall {
  max-height: 300px;
  min-width: 120px;
  max-width: 260px;
  border-radius: 1rem;
  box-shadow: 1px 1px 4px #aaa;
  background-color: white;
  overflow: hidden; }
  .tools.productCardSmall.have {
    background-color: #e9f3ff; }
  .tools.productCardSmall .details {
    overflow: hidden;
    height: 100%;
    justify-content: space-between; }
    .tools.productCardSmall .details,
    .tools.productCardSmall .details .prodDit,
    .tools.productCardSmall .details .storeDit {
      display: flex;
      flex-direction: column; }
    .tools.productCardSmall .details .top {
      cursor: pointer;
      align-items: flex-end;
      justify-items: flex-end; }
      .tools.productCardSmall .details .top .image,
      .tools.productCardSmall .details .top .logo {
        grid-area: 1/1;
        object-fit: contain;
        object-position: center;
        background-color: white; }
      .tools.productCardSmall .details .top .image {
        padding: 1rem;
        height: 120px;
        border: 1px solid #00000000;
        width: calc(100% - 2.1rem); }
        .tools.productCardSmall .details .top .image:hover {
          border-radius: 1rem;
          border-color: #1d2772; }
          .tools.productCardSmall .details .top .image:hover ~ .logo {
            border-radius: 1rem;
            transform: translate(1px, -1px); }
      .tools.productCardSmall .details .top .logo {
        height: 35px;
        width: 75px;
        padding: 10px;
        border-radius: 0 0.5rem 0 0;
        border: 1px solid #00000000; }
        .tools.productCardSmall .details .top .logo:hover {
          border-radius: 1rem;
          border-color: #1d2772; }
    .tools.productCardSmall .details .bot {
      height: 100px; }
      .tools.productCardSmall .details .bot .prodDit {
        align-self: flex-start;
        padding: 1rem;
        color: #aaa; }
        .tools.productCardSmall .details .bot .prodDit *:hover {
          color: #1d2772; }
        .tools.productCardSmall .details .bot .prodDit .name {
          cursor: pointer;
          width: 200px;
          max-height: 3em;
          overflow: hidden;
          font-size: 1.1rem; }
        .tools.productCardSmall .details .bot .prodDit .company {
          cursor: pointer;
          font-size: 0.9rem;
          width: fit-content; }

.profileBtn {
  font-size: 1rem !important;
  width: fit-content;
  gap: 1rem;
  cursor: pointer; }
  .profileBtn .profileImg {
    height: 40px;
    width: 40px;
    margin-right: 5px; }
  .profileBtn .menuWrapper {
    position: relative;
    z-index: 110; }
    .profileBtn .menuWrapper .menu {
      z-index: 110;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
      pointer-events: none;
      white-space: nowrap;
      transform: translate(calc(100% - 2px), 0);
      position: absolute;
      background-color: white;
      padding: 1rem;
      border-radius: 0.5rem;
      opacity: 0;
      align-items: flex-start;
      gap: 0.5rem; }
      .profileBtn .menuWrapper .menu:before {
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        top: calc(-1rem - 4px);
        left: 10%;
        content: '';
        border-width: 10px;
        border-bottom-color: white; }
      .profileBtn .menuWrapper .menu .iconBtn {
        color: black; }
  .profileBtn:focus .dropicon {
    transform: rotateX(-180deg); }
  .profileBtn:focus .menuWrapper .menu {
    pointer-events: all;
    opacity: 1;
    transform: translate(calc(100% - 2px), calc(3rem - 3px)); }
  .profileBtn .fullPopup .content .children .wrapper .iconBtn {
    min-height: 3rem;
    min-width: 4rem;
    padding: 0.5rem 1rem;
    margin-top: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 2px 2px 5px #aaa;
    color: black; }
  .profileBtn .fullPopup .content .children .wrapper .popupTitle {
    margin-bottom: 1rem; }
  .profileBtn .fullPopup .content .children .wrapper .linesWrapper {
    padding-top: 0.5rem;
    width: 100%; }
    .profileBtn .fullPopup .content .children .wrapper .linesWrapper .alongLine,
    .profileBtn .fullPopup .content .children .wrapper .linesWrapper .acrossLine {
      border: 2px solid gray;
      border-radius: 2px;
      margin-bottom: 5px; }
    .profileBtn .fullPopup .content .children .wrapper .linesWrapper .alongLine {
      height: 1rem;
      width: 0; }
    .profileBtn .fullPopup .content .children .wrapper .linesWrapper .acrossLine {
      width: calc(100% - 6rem); }
  .profileBtn .fullPopup .content .children .wrapper .orgs,
  .profileBtn .fullPopup .content .children .wrapper .stores {
    display: flex;
    align-items: center;
    gap: 1rem; }
  .profileBtn .fullPopup .content .children .wrapper .iconBtn {
    width: 8rem; }
    .profileBtn .fullPopup .content .children .wrapper .iconBtn__icon {
      width: 100%;
      justify-content: center; }
    .profileBtn .fullPopup .content .children .wrapper .iconBtn.org {
      min-height: 4rem; }
      .profileBtn .fullPopup .content .children .wrapper .iconBtn.org::before {
        position: relative;
        content: '';
        left: calc(-50% + 2px);
        transform: translateY(calc(-100% - 1.5rem));
        height: 1rem;
        width: 0px;
        border: 2px solid gray;
        border-radius: 2px; }
      .profileBtn .fullPopup .content .children .wrapper .iconBtn.org:hover {
        transform: translateY(0.5rem); }
  .profileBtn .fullPopup .content .children .wrapper .btns {
    gap: 1rem; }
  .profileBtn .fullPopup .content .children .noStoreBtns {
    gap: 1rem; }

.tools.rate {
  width: fit-content;
  direction: ltr;
  white-space: nowrap; }

.tools.scroll {
  scroll-behavior: smooth;
  gap: 20px;
  padding: 10px;
  user-select: none; }

.tools.searchBar {
  background-color: white;
  border-radius: 0.5rem;
  min-width: fit-content;
  width: 600px;
  max-width: 100%; }
  .tools.searchBar .input {
    background-color: white; }
  .tools.searchBar .icon {
    height: 100%;
    width: 20px;
    padding-right: 1rem;
    padding-left: 0.5rem; }

.tools.select {
  border: 0;
  outline: 0;
  margin-left: -2rem;
  width: fit-content;
  height: 50px;
  padding: 0 1rem;
  background-color: #d7e5ed;
  border-radius: 0.5rem;
  font-size: 0.8rem; }

.tools.storeCard {
  background-color: #f8fcff;
  box-shadow: 0 0 5px #aaa;
  margin: 0.5rem;
  padding: 0 0.5rem;
  border-radius: 1rem; }
  .tools.storeCard .storeLogo {
    background-color: white;
    height: 85px;
    width: 85px;
    border-radius: 1rem;
    margin: 0.5rem; }
  .tools.storeCard .storeInfo {
    width: max-content;
    padding: 0 2rem;
    white-space: nowrap; }
    .tools.storeCard .storeInfo .meters {
      color: black; }
  .tools.storeCard .btn {
    border-radius: 1rem;
    height: calc(100% - 1rem);
    box-shadow: 0 0 5px #aaa;
    font-size: 1.3rem;
    padding: 0 2.5rem; }

.tools.storeHeader {
  width: 100%;
  height: 220px;
  border-radius: 1rem;
  overflow: hidden; }
  .tools.storeHeader .ditals {
    color: white;
    justify-content: space-between;
    padding: 10px 20px;
    height: 50px;
    align-self: flex-end;
    background-color: rgba(0, 0, 0, 0.6); }
    .tools.storeHeader .ditals .first,
    .tools.storeHeader .ditals .second {
      gap: 2rem; }
    .tools.storeHeader .ditals .first .name {
      font-size: 1.2rem; }
    .tools.storeHeader .ditals .first .logo {
      background-color: white;
      object-fit: contain;
      object-position: center;
      padding: 0;
      height: 40px;
      width: 40px;
      border-radius: 0.5rem; }
    .tools.storeHeader .ditals .second div {
      gap: 5px; }
  .tools.storeHeader > .bg {
    height: 220px;
    width: 100%;
    object-position: center;
    object-fit: cover; }

.tools.switch {
  position: relative;
  width: auto;
  height: 34px;
  border-radius: 34px;
  cursor: pointer;
  user-select: none;
  left: 100%; }
  .tools.switch .checkbox {
    opacity: 0;
    width: 0;
    height: 0; }
    .tools.switch .checkbox:checked + .slider {
      background-color: #3e44e0; }
    .tools.switch .checkbox:checked + .slider:after {
      transform: translateX(26px); }
  .tools.switch .slider {
    transition: all 0.4s;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #3698ff;
    border-radius: 34px;
    display: flex;
    width: max-content;
    padding: 0 1.5rem;
    height: 100%;
    align-items: center;
    justify-content: center; }
    .tools.switch .slider .text {
      color: white; }
    .tools.switch .slider:after {
      transition: all 0.4s;
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      border-radius: 50%; }

.tools.textarea {
  border: 0;
  outline: 0;
  resize: none;
  width: 100%;
  height: 4rem;
  padding: 1rem;
  background-color: #d7e5ed;
  border-radius: 0.5rem;
  font-size: 0.8rem; }
  .tools.textarea:disabled {
    background-color: #9fe7d1; }

.tools.title {
  width: fit-content;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 4px;
  color: #1d2772;
  font-weight: bold; }
  .tools.title svg {
    position: relative;
    top: 2px;
    color: #68c4e5; }

.topCategory.tools {
  border-radius: 0.5rem; }
  .topCategory.tools img {
    border-radius: 0.5rem;
    object-fit: cover;
    height: 100px;
    opacity: 0.35;
    width: 300px; }
  .topCategory.tools span {
    border-radius: 0.5rem;
    width: 100%;
    height: 100%;
    opacity: 0.35;
    background-image: linear-gradient(to right, #209cff, #331dd9); }
  .topCategory.tools div {
    z-index: 1;
    color: white;
    font-size: 1.5rem;
    filter: drop-shadow(1px 1px 10px black); }

.draggableUploader.tools {
  border-radius: 0.5rem;
  border: dashed 2px #1d80df;
  display: grid;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  overflow: hidden; }
  .draggableUploader.tools .btns {
    width: inherit;
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .draggableUploader.tools .btns .text {
      width: 100%; }
    .draggableUploader.tools .btns .btnText {
      width: fit-content;
      cursor: pointer;
      font-weight: bold; }
      .draggableUploader.tools .btns .btnText:hover {
        text-decoration-line: underline; }
  .draggableUploader.tools #dragFile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.8); }

.userProfile {
  background-color: #f8fcff;
  height: 100vh;
  width: 100vw;
  direction: rtl;
  margin: auto; }
  .userProfile .hide {
    opacity: 0;
    pointer-events: none; }
  .userProfile .logo {
    height: calc(100% - 4rem);
    width: 100%;
    background: #e5f3fe;
    padding: 2rem 1rem;
    object-fit: contain;
    object-position: center; }
  .userProfile .pageWrapper {
    width: 100vw;
    height: calc(100vh - 100px); }
    .userProfile .pageWrapper > .content {
      width: calc(100vw - 200px); }
    .userProfile .pageWrapper .sideBarUser {
      min-width: 200px; }
  @media only screen and (max-width: 1024px) {
    .userProfile .profileBtn.notLogged ~ .searchBarAndBtnWrapper.search {
      width: calc(90vw - 200px - 10rem);
      transform: translateX(calc(45vw - 0.5rem - 50% - 100px)); } }
  @media only screen and (max-width: 768px) {
    .userProfile .torBar {
      height: 205px; }
      .userProfile .torBar .searchBarAndBtnWrapper, .userProfile .torBar .searchBarAndBtnWrapper.category, .userProfile .torBar .searchBarAndBtnWrapper.search {
        transform: translateY(4rem) !important;
        width: calc(90vw - 1rem) !important; }
      .userProfile .torBar .twoBtnSwitch {
        margin: 1rem 0;
        align-self: flex-end; } }
  @media only screen and (max-width: 375px) {
    .userProfile .torBar .profileBtn .btn {
      padding: 0 1rem; }
    .userProfile .torBar .twoBtnSwitch .btns .iconBtn {
      padding: 0 3rem; } }

.storeProfile {
  background-color: #f8fcff;
  height: 100vh;
  width: 100vw;
  direction: rtl;
  margin: auto; }
  .storeProfile .hide {
    opacity: 0;
    pointer-events: none; }
  .storeProfile .logo {
    height: calc(100% - 4rem);
    width: 100%;
    background: #e5f3fe;
    padding: 2rem 1rem;
    object-fit: contain;
    object-position: center; }
  .storeProfile .pageWrapper {
    width: 100vw;
    height: calc(100vh - 100px); }
    .storeProfile .pageWrapper .content {
      width: calc(100vw - 200px); }
    .storeProfile .pageWrapper .sideBarStore {
      min-width: 200px; }
  @media only screen and (max-width: 1024px) {
    .storeProfile .profileBtn.notLogged ~ .searchBarAndBtnWrapper.search {
      width: calc(90vw - 200px - 10rem);
      transform: translateX(calc(45vw - 0.5rem - 50% - 100px)); } }
  @media only screen and (max-width: 768px) {
    .storeProfile .torBar {
      height: 205px; }
      .storeProfile .torBar .searchBarAndBtnWrapper, .storeProfile .torBar .searchBarAndBtnWrapper.category, .storeProfile .torBar .searchBarAndBtnWrapper.search {
        transform: translateY(4rem) !important;
        width: calc(90vw - 1rem) !important; }
      .storeProfile .torBar .twoBtnSwitch {
        margin: 1rem 0;
        align-self: flex-end; } }
  @media only screen and (max-width: 375px) {
    .storeProfile .torBar .profileBtn .btn {
      padding: 0 1rem; }
    .storeProfile .torBar .twoBtnSwitch .btns .iconBtn {
      padding: 0 3rem; } }

.loginPage {
  height: 95vh;
  background-image: url("/public/assets/images/bgs/body_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  padding-top: 5vh; }
  .loginPage .img {
    width: 500px;
    height: 200px;
    object-fit: contain;
    object-position: center; }
  .loginPage .fields {
    background-color: white;
    padding: 3rem;
    border-radius: 1rem;
    width: 300px; }
    .loginPage .fields .input::-webkit-input-placeholder {
      text-align: right; }
    .loginPage .fields .input {
      background-color: white;
      border: solid #1d80df 2px;
      color: #1d80df; }
    .loginPage .fields .iconBtn {
      color: black;
      padding: 1rem 2rem;
      border-radius: 0.5rem;
      width: 100%; }
    .loginPage .fields .forgot,
    .loginPage .fields .iconBtn {
      margin-top: 1rem; }
    .loginPage .fields .loginBtn {
      background-color: #1d80df;
      color: white; }
    .loginPage .fields .registerBtn {
      border: solid #1d80df 2px;
      color: #1d80df; }
    .loginPage .fields .title {
      align-self: flex-end;
      direction: rtl; }
  .loginPage .fullPopup .content {
    direction: rtl; }
  .loginPage .footer {
    display: flex;
    width: 400px; }
    .loginPage .footer .backBtn {
      background-color: white;
      padding: 1rem 2rem 1rem 1.5rem;
      margin-top: 1rem;
      border-radius: 1rem; }
      .loginPage .footer .backBtn:hover {
        transform: translateX(-10px); }

.registerPage {
  height: 100vh;
  background-image: url("/public/assets/images/bgs/body_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center; }
  .registerPage .img {
    width: 500px;
    height: 200px;
    object-fit: contain;
    object-position: center; }
  .registerPage .fields {
    background-color: white;
    padding: 3rem;
    border-radius: 1rem;
    width: 300px;
    gap: 1rem;
    overflow: hidden; }
    .registerPage .fields .input::-webkit-input-placeholder {
      text-align: right; }
    .registerPage .fields .steps {
      width: calc(300% + 20rem);
      gap: 10rem; }
      .registerPage .fields .steps .step {
        width: inherit; }
        .registerPage .fields .steps .step .title {
          align-self: flex-end;
          direction: rtl; }
        .registerPage .fields .steps .step .iconBtn {
          color: black;
          margin-top: 1rem;
          background-color: #1d80df;
          border: 2px solid;
          color: white;
          direction: rtl;
          padding: 1rem 2rem;
          border-radius: 0.5rem; }
      .registerPage .fields .steps .btns {
        gap: 1rem; }
      .registerPage .fields .steps.stepOne {
        transform: translateX(38%); }
      .registerPage .fields .steps.stepTwo {
        transform: translateX(0); }
      .registerPage .fields .steps.stepThree {
        transform: translateX(-38%); }
  .registerPage .footer {
    display: flex;
    width: 400px; }
    .registerPage .footer .backBtn {
      background-color: white;
      padding: 1rem 2rem 1rem 1.5rem;
      margin-top: 1rem;
      border-radius: 1rem; }
      .registerPage .footer .backBtn:hover {
        transform: translateX(-10px); }

.resetPasswordPage {
  height: 95vh;
  background-image: url("/public/assets/images/bgs/body_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  padding-top: 5vh; }
  .resetPasswordPage .fields {
    background-color: white;
    padding: 3rem;
    border-radius: 1rem;
    width: 300px; }
    .resetPasswordPage .fields .input::-webkit-input-placeholder {
      text-align: right; }
    .resetPasswordPage .fields .input {
      background-color: white;
      border: solid #1d80df 2px;
      color: #1d80df; }
    .resetPasswordPage .fields .inputLable .text {
      background-color: white;
      border-color: white; }
  .resetPasswordPage .img {
    width: 500px;
    height: 200px;
    object-fit: contain;
    object-position: center; }
  .resetPasswordPage .iconBtn {
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    border: solid 2px;
    width: 100%; }
  .resetPasswordPage .forgot,
  .resetPasswordPage .iconBtn {
    margin-top: 1rem; }
  .resetPasswordPage .loginBtn {
    background-color: #1d80df;
    color: white; }
  .resetPasswordPage .registerBtn {
    border: solid #1d80df 2px;
    color: #1d80df; }
  .resetPasswordPage .title {
    align-self: flex-end;
    direction: rtl; }

.storePage {
  height: 100vh;
  width: 100vw;
  direction: rtl;
  overflow-y: auto; }
  .storePage .torBar {
    z-index: 100;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 70px; }
    .storePage .torBar.scrolled {
      background-color: #ffffffdd;
      box-shadow: 0 0 10px #aaa; }
    .storePage .torBar .logoBuyWay,
    .storePage .torBar .profileBtn,
    .storePage .torBar .searchBarAndBtnWrapper {
      align-self: flex-start;
      margin-top: 1rem;
      filter: drop-shadow(0 0 2px #aaa); }
    .storePage .torBar .profileBtn {
      justify-self: flex-end;
      margin-left: calc(5% + 0.5rem); }
    .storePage .torBar .logoBuyWay {
      margin-right: calc(5% + 0.5rem);
      height: 50px;
      width: 70px; }
    .storePage .torBar .searchBarAndBtnWrapper {
      align-items: center;
      height: 44px;
      overflow: hidden;
      border-radius: 2rem;
      justify-self: center; }
      .storePage .torBar .searchBarAndBtnWrapper .btn {
        min-width: 100px;
        border-radius: 0; }
      .storePage .torBar .searchBarAndBtnWrapper.search {
        width: 500px; }
        .storePage .torBar .searchBarAndBtnWrapper.search .searchBar {
          width: 400px;
          border-radius: 0; }
      .storePage .torBar .searchBarAndBtnWrapper.category {
        width: calc(90vw - 200px - 2rem);
        transform: translateX(calc(45vw - 0.5rem - 50% - 100px));
        border-radius: 0 2rem 2rem 0; }
        .storePage .torBar .searchBarAndBtnWrapper.category .categoriesScroll {
          margin: 0;
          height: 100%;
          border-radius: 0; }
          .storePage .torBar .searchBarAndBtnWrapper.category .categoriesScroll .header {
            padding: 0; }
          .storePage .torBar .searchBarAndBtnWrapper.category .categoriesScroll .categories {
            padding: 0;
            margin-right: 1rem;
            padding-left: 2px; }
            .storePage .torBar .searchBarAndBtnWrapper.category .categoriesScroll .categories::-webkit-scrollbar {
              display: none; }
            .storePage .torBar .searchBarAndBtnWrapper.category .categoriesScroll .categories .categoryCard {
              border-radius: 2rem;
              gap: 0; }
              .storePage .torBar .searchBarAndBtnWrapper.category .categoriesScroll .categories .categoryCard .image {
                padding: 0;
                height: 20px; }
              .storePage .torBar .searchBarAndBtnWrapper.category .categoriesScroll .categories .categoryCard .name {
                font-size: 0.8rem;
                color: black;
                padding: 0 1rem;
                margin: 0 auto;
                white-space: nowrap; }
              .storePage .torBar .searchBarAndBtnWrapper.category .categoriesScroll .categories .categoryCard.selected {
                transform: scale(1); }
  .storePage .header {
    width: 100%;
    overflow: hidden; }
    .storePage .header .bg {
      object-fit: cover;
      object-position: 100% 100%;
      width: 100%;
      height: 500px; }
    .storePage .header .logo,
    .storePage .header .name,
    .storePage .header .details {
      z-index: 3; }
    .storePage .header .logo,
    .storePage .header .name,
    .storePage .header .details {
      color: white;
      align-self: flex-end;
      width: fit-content;
      margin-bottom: 1rem; }
    .storePage .header .logo {
      background-color: white;
      object-fit: contain;
      object-position: center;
      height: 60px;
      width: 60px;
      margin-right: calc(5% + 0.5rem); }
    .storePage .header .details {
      justify-self: flex-end;
      margin-left: 5%;
      gap: 1rem;
      align-items: center; }
      .storePage .header .details > .rate {
        font-size: 30px; }
      .storePage .header .details > .like {
        font-size: 20px; }
    .storePage .header > .name {
      margin-right: calc(5% + 50px + 3rem);
      font-size: 50px; }
    .storePage .header > .shadow {
      position: relative;
      top: 100%;
      box-shadow: 0 -100px 150px black;
      width: 100%;
      height: 100%;
      z-index: 2; }
  .storePage > .products {
    margin: auto;
    width: 90%; }
    .storePage > .products .sectionOne {
      width: 100%;
      align-items: flex-end;
      gap: 1rem; }
      .storePage > .products .sectionOne .productsScroll {
        width: 100%; }

.NDPRegister {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  width: 100vw;
  gap: 1rem;
  direction: rtl; }
  .NDPRegister strong {
    margin-top: 1rem; }
  .NDPRegister div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 500px;
    padding-bottom: 2px;
    border-bottom: 2px solid; }
  .NDPRegister input,
  .NDPRegister input[type='file'],
  .NDPRegister select,
  .NDPRegister button,
  .NDPRegister .btn {
    display: block;
    height: 30px;
    width: 300px;
    border: 2px solid;
    padding: 2px 5px; }
  .NDPRegister input[type='checkbox'] {
    display: flex;
    align-items: flex-start;
    width: 30px;
    padding: 0; }
  .NDPRegister select {
    width: 313px; }

.NDPStore form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  width: 100vw;
  gap: 1rem;
  direction: rtl; }
  .NDPStore form strong {
    margin-top: 1rem; }
  .NDPStore form div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 500px;
    padding-bottom: 2px;
    border-bottom: 2px solid; }
  .NDPStore form input,
  .NDPStore form input[type='file'],
  .NDPStore form select,
  .NDPStore form button,
  .NDPStore form .btn {
    display: block;
    height: 30px;
    width: 300px;
    border: 2px solid;
    padding: 2px 5px; }
  .NDPStore form input[type='checkbox'] {
    display: flex;
    align-items: flex-start;
    width: 30px;
    padding: 0; }
  .NDPStore form select {
    width: 313px; }

.createNewPage.page {
  height: 100vh;
  background-image: url("/public/assets/images/bgs/body_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: flex-start; }
  .createNewPage.page .img {
    width: 500px;
    height: 170px;
    object-fit: contain;
    object-position: center;
    filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.25)); }
  .createNewPage.page .fields {
    background-color: white;
    padding: 3rem;
    border-radius: 1rem;
    gap: 2rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25); }
    .createNewPage.page .fields.open {
      display: grid;
      grid-template-columns: 1fr 1fr; }
      .createNewPage.page .fields.open .createBtn {
        grid-area: 2/1/3/3;
        width: 75%; }
      .createNewPage.page .fields.open .backToHome {
        grid-area: 3/1/4/3; }
    .createNewPage.page .fields .iconBtn {
      margin-top: 1rem;
      background-color: #1d80df;
      border: 2px solid;
      color: white;
      direction: rtl;
      padding: 1rem 2rem;
      border-radius: 0.5rem; }
    .createNewPage.page .fields .input::-webkit-input-placeholder {
      text-align: right; }
    .createNewPage.page .fields .inputs {
      width: 300px; }
      .createNewPage.page .fields .inputs .title {
        align-self: flex-end;
        direction: rtl; }
      .createNewPage.page .fields .inputs .isLabel {
        cursor: pointer;
        user-select: none;
        margin: 2rem 0 1rem 0;
        display: flex;
        width: 100%;
        flex-direction: row-reverse;
        align-items: center;
        gap: 1rem; }
        .createNewPage.page .fields .inputs .isLabel .input[type='checkbox'] {
          width: 30px;
          height: 30px;
          border: 2px solid #1d80df; }
          .createNewPage.page .fields .inputs .isLabel .input[type='checkbox']:checked {
            background-color: #1d80df; }
            .createNewPage.page .fields .inputs .isLabel .input[type='checkbox']:checked::before {
              transform: translate(2px, -2px); }
      .createNewPage.page .fields .inputs .selects {
        gap: 1rem; }
        .createNewPage.page .fields .inputs .selects .select {
          direction: rtl; }
      .createNewPage.page .fields .inputs.checks {
        height: 100%;
        justify-content: center; }
        .createNewPage.page .fields .inputs.checks .isLabel {
          margin: 0.5rem 0; }
      .createNewPage.page .fields .inputs .logoWrapper {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between; }
        .createNewPage.page .fields .inputs .logoWrapper img {
          margin-top: 1rem;
          height: 44px;
          border: 2px solid #1d80df;
          padding: 1rem;
          border-radius: 0.5rem; }
      .createNewPage.page .fields .inputs .placeChangeBtnWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .createNewPage.page .fields .inputs .placeChangeBtnWrapper div {
          border: 2px solid #1d80df;
          border-radius: 0.5rem;
          padding: 1rem;
          margin-top: 1rem; }
    .createNewPage.page .fields .backToHome {
      text-align: center;
      width: 100%; }
  .createNewPage.page .footer {
    display: flex;
    width: 400px; }
    .createNewPage.page .footer .backBtn {
      background-color: white;
      padding: 1rem 2rem 1rem 1.5rem;
      margin-top: 1rem;
      border-radius: 1rem;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25); }
      .createNewPage.page .footer .backBtn:hover {
        transform: translateX(-10px); }
  .createNewPage.page .fields.open ~ .footer {
    width: 730px; }
  .createNewPage.page .fullPopup .placeMapWrapper {
    display: flex;
    flex-direction: column;
    height: 70vh;
    align-items: center;
    gap: 1rem; }
    .createNewPage.page .fullPopup .placeMapWrapper .placeMap {
      height: 100%;
      border-radius: 0.5rem;
      overflow: hidden; }
      .createNewPage.page .fullPopup .placeMapWrapper .placeMap .search {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        border-radius: 0.5rem;
        overflow: hidden; }
    .createNewPage.page .fullPopup .placeMapWrapper .btnWrapper {
      display: flex;
      gap: 1rem; }
      .createNewPage.page .fullPopup .placeMapWrapper .btnWrapper .iconBtn {
        width: fit-content;
        padding: 1rem 2rem;
        border-radius: 0.5rem;
        color: white; }
        .createNewPage.page .fullPopup .placeMapWrapper .btnWrapper .iconBtn:has(.fi-rr-check) {
          background-color: #1d80df; }
        .createNewPage.page .fullPopup .placeMapWrapper .btnWrapper .iconBtn:has(.fi-sr-cross) {
          background-color: #ff818d; }

.homeUserPage {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem 1rem 0 0; }
  .homeUserPage .backBtn {
    position: fixed;
    top: calc(100vh - 1rem - 50px);
    left: calc(100vw - 1rem - 50px -200px);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #209cff 0%, #331dd9 100%);
    z-index: 100;
    color: white !important; }
  .homeUserPage .marginTop {
    margin-top: 5rem; }
  .homeUserPage .categoriesScroll {
    margin: 0 0 1rem 0;
    z-index: 50;
    position: sticky;
    top: 0; }
  .homeUserPage .homeContent {
    display: grid;
    grid-template-columns: repeat(2, 100%); }
    .homeUserPage .homeContent .products > .image {
      margin-top: 3rem;
      background: none;
      padding: 0;
      min-width: 400px;
      width: fit-content;
      max-width: 100%;
      height: 300px; }
    .homeUserPage .homeContent .stores .mapWrapper {
      position: relative;
      top: -225px;
      margin-right: 1rem;
      height: calc(100vh - 100px - 1rem);
      z-index: 0; }
    .homeUserPage .homeContent.active {
      transform: translate(100%); }
  .homeUserPage.active {
    padding: 1rem 0 0 0;
    overflow: hidden; }
    .homeUserPage.active .categoriesScroll {
      margin: 1rem; }
    .homeUserPage.active .stores .mapWrapper {
      left: 10px; }
    .homeUserPage.active ::-webkit-scrollbar {
      display: none; }

.storeMapPopup.homeUserPage {
  position: absolute;
  top: -200px;
  left: calc(200px + 2rem - 100vw);
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 1px 1px 10px #00000040;
  min-height: 600px;
  height: 60vh;
  min-width: 400px;
  width: 20vw;
  border-radius: 1rem;
  display: grid;
  grid-template-rows: 25px 80px 1fr 80px;
  padding: 1rem;
  align-items: center;
  justify-content: center; }
  .storeMapPopup.homeUserPage .iconBtn.close {
    height: 25px;
    width: 25px;
    background-color: #209cff;
    color: white;
    border-radius: 50%;
    font-size: 0.5rem;
    justify-self: flex-end; }
  .storeMapPopup.homeUserPage .details {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .storeMapPopup.homeUserPage .details .left {
      display: flex;
      align-items: center; }
      .storeMapPopup.homeUserPage .details .left .iconBtn {
        height: 60px;
        width: 60px;
        border-radius: 10px;
        box-shadow: 1px 1px 5px #00000040;
        overflow: hidden; }
      .storeMapPopup.homeUserPage .details .left .nameAndRate {
        padding: 1rem; }
    .storeMapPopup.homeUserPage .details .right .close {
      color: red; }
    .storeMapPopup.homeUserPage .details .right .open {
      color: green; }
  .storeMapPopup.homeUserPage .imagesGrid {
    display: grid;
    grid-template-columns: 200px 200px;
    grid-template-rows: 300px 100px;
    gap: 0.5rem; }
    .storeMapPopup.homeUserPage .imagesGrid .iconBtn.big {
      grid-area: 1/1/2/3; }
      .storeMapPopup.homeUserPage .imagesGrid .iconBtn.big .image {
        object-fit: contain; }
    .storeMapPopup.homeUserPage .imagesGrid .iconBtn {
      height: 100%;
      width: 100%; }
  .storeMapPopup.homeUserPage .btn {
    font-size: 2rem; }

.myProductsPage.storePage .menu {
  padding: 2rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around; }
  .myProductsPage.storePage .menu .iconBtn {
    width: 150px;
    flex-direction: column;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: white;
    color: #172573;
    border: 1px solid #172573; }
    .myProductsPage.storePage .menu .iconBtn__icon {
      transform: translateY(3px); }
    .myProductsPage.storePage .menu .iconBtn:hover, .myProductsPage.storePage .menu .iconBtn.selected {
      background-color: #172573;
      color: white; }
    .myProductsPage.storePage .menu .iconBtn.selected {
      scale: 1.1; }

.addProductPage {
  padding: 1rem; }
  .addProductPage .selectProducts {
    min-width: calc(2rem + 260px + 1rem + 5px);
    width: calc(2rem + 260px + 1rem + 5px);
    margin-left: 0.5rem; }
    .addProductPage .selectProducts .gridProducts {
      overflow-y: auto;
      width: 100%;
      background: linear-gradient(270deg, #34a4f477 0%, #9fe7d177 100%);
      margin: 0; }
    .addProductPage .selectProducts > .iconBtn {
      align-self: center;
      height: fit-content;
      border-radius: 2rem 0 0 2rem;
      padding: 1rem 0.5rem;
      background: linear-gradient(270deg, #34a5f4 0%, #9fe7d1 100%);
      color: black; }
    .addProductPage .selectProducts.active {
      width: calc(100% - 1rem); }
      .addProductPage .selectProducts.active > .iconBtn .iconBtn__icon {
        transform: rotate(180deg); }
  .addProductPage .inputs {
    margin: 0 auto;
    justify-content: center;
    gap: 0.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(10, 1fr);
    width: 100%; }
    .addProductPage .inputs .image {
      height: 100%;
      width: calc(100% - 2rem);
      height: calc(100% - 2rem);
      border: 1rem solid #d7e5ed;
      background-color: white; }
    .addProductPage .inputs .inputAndDropdown {
      height: 100%;
      width: calc(100% - 2rem); }
      .addProductPage .inputs .inputAndDropdown .input {
        border-radius: 0 0.5rem 0.5rem 0;
        height: calc(100% - 2px); }
      .addProductPage .inputs .inputAndDropdown .dangerColor ~ .select {
        background-color: #ff818d; }
      .addProductPage .inputs .inputAndDropdown .select {
        height: 100%;
        border-radius: 0.5rem 0 0 0.5rem; }
      .addProductPage .inputs .inputAndDropdown .outLineDangerColor,
      .addProductPage .inputs .inputAndDropdown .outLineSafeColor {
        border-left: none !important; }
      .addProductPage .inputs .inputAndDropdown .outLineDangerColor ~ .select {
        border: 1px solid #ff818d !important;
        color: #ff818d !important; }
      .addProductPage .inputs .inputAndDropdown .outLineSafeColor ~ .select {
        border: 1px solid #bbf2c8 !important;
        color: black !important; }
      .addProductPage .inputs .inputAndDropdown .outLineDangerColor ~ .select,
      .addProductPage .inputs .inputAndDropdown .outLineSafeColor ~ .select {
        background-color: white !important;
        border-right: none !important; }
    .addProductPage .inputs .btn {
      width: 100%;
      border-radius: 0.5rem;
      margin-right: 1rem; }
    .addProductPage .inputs .textarea {
      width: calc(100% - 2rem);
      height: calc(100% - 2rem); }
    .addProductPage .inputs .input {
      width: calc(100% - 2rem);
      height: 100%;
      padding: 0 1rem; }
    .addProductPage .inputs .btn {
      justify-self: center;
      margin: 0;
      width: 60%; }
    .addProductPage .inputs .item1 {
      grid-area: 1/2/6/3; }
    .addProductPage .inputs .item2 {
      grid-area: 6/2/11/3; }
    .addProductPage .inputs .btns {
      align-self: center;
      grid-area: 9/1/11/2;
      gap: 1rem; }
      .addProductPage .inputs .btns .inStore {
        background: linear-gradient(270deg, #34a5f4 0%, #9fe7d1 100%); }
      .addProductPage .inputs .btns .clear {
        width: fit-content;
        padding: 1rem 3rem;
        background: linear-gradient(270deg, #ff818d 0%, #ffa581 100%); }
    .addProductPage .inputs.active {
      width: 400px;
      display: flex;
      flex-direction: column; }
      .addProductPage .inputs.active .image {
        max-height: 200px; }

.homeStorePage {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto; }
  .homeStorePage .twoBtnSwitch {
    margin: 1rem 0; }
  .homeStorePage .homeContent {
    display: grid;
    grid-template-columns: repeat(2, 100%); }
    .homeStorePage .homeContent .products > .image {
      margin-top: 3rem;
      background: none;
      padding: 0;
      min-width: 400px;
      width: fit-content;
      max-width: 100%;
      height: 300px; }
    .homeStorePage .homeContent.active {
      transform: translate(100%); }

.costumersClubPage.storePage {
  align-items: flex-start; }
  .costumersClubPage.storePage .title {
    margin: 1rem; }
  .costumersClubPage.storePage .bg {
    height: 100%;
    width: 100%;
    opacity: 0.04;
    pointer-events: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../../../public/assets/images/bgs/costumersClubBg.png"); }

.inOutPage.storePage {
  align-items: flex-start; }
  .inOutPage.storePage .title {
    margin: 1rem; }
  .inOutPage.storePage .bg {
    height: 100%;
    width: 100%;
    pointer-events: none;
    opacity: 0.1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../../../public/assets/images/bgs/inOutBg.png"); }

.myEmployeesPage.storePage {
  align-items: flex-start; }
  .myEmployeesPage.storePage .title {
    margin: 1rem; }
  .myEmployeesPage.storePage .bg {
    height: 100%;
    width: 100%;
    pointer-events: none;
    opacity: 0.03;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../../../public/assets/images/bgs/myEmployeesBg.png"); }

.myOrdersPage.storePage {
  align-items: flex-start; }
  .myOrdersPage.storePage .title {
    margin: 1rem; }
  .myOrdersPage.storePage .bg {
    height: 100%;
    width: 100%;
    pointer-events: none;
    opacity: 0.05;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../../../public/assets/images/bgs/myOrdersBg.png"); }

.suppliersPage.storePage {
  align-items: flex-start; }
  .suppliersPage.storePage .title {
    margin: 1rem; }
  .suppliersPage.storePage .bg {
    height: 100%;
    width: 100%;
    pointer-events: none;
    opacity: 0.05;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../../../public/assets/images/bgs/suppliersBg.png"); }

.salesPage.storePage {
  align-items: flex-start; }
  .salesPage.storePage .title {
    margin: 1rem; }
  .salesPage.storePage .bg {
    height: 100%;
    width: 100%;
    pointer-events: none;
    opacity: 0.04;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../../../public/assets/images/bgs/salesBg.png"); }

.vipCostumersPage.storePage {
  align-items: flex-start; }
  .vipCostumersPage.storePage .title {
    margin: 1rem; }
  .vipCostumersPage.storePage .bg {
    height: 100%;
    width: 100%;
    pointer-events: none;
    opacity: 0.05;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../../../public/assets/images/bgs/vipCostumersBg.png"); }

.sideBarUser {
  background-color: #f7faff;
  display: flex;
  flex-direction: column;
  gap: 1.5rem; }
  .sideBarUser.menuWrapper {
    z-index: 100;
    transform: translateX(2.5rem);
    min-width: 0px !important;
    background-color: #f7faff; }
  .sideBarUser.colse ~ .sideBarUser.menuWrapper {
    transform: translateX(0); }
  .sideBarUser.menu {
    margin: 1rem;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    min-width: 50px !important;
    color: white;
    background: linear-gradient(90deg, #3e44e0 0%, #3698ff 100%); }
  .sideBarUser .sideIcon {
    height: 50px;
    border-radius: 0.5rem 0 0 0.5rem;
    overflow: hidden;
    justify-content: flex-start; }
    .sideBarUser .sideIcon .iconBtn__icon {
      padding-right: 2rem;
      margin-left: 1rem;
      font-size: 1.2rem; }
    .sideBarUser .sideIcon .iconBtn.active {
      width: 100%;
      font-weight: bold;
      color: #4038ad;
      background: #e5f3fe;
      background: linear-gradient(45deg, #f2f7fc 0%, #f1f8fc 100%); }
    .sideBarUser .sideIcon .iconBtn__active {
      position: absolute;
      background: linear-gradient(135deg, #3698ff 0%, #3e44e0 100%);
      border-radius: 3px 0 0 3px;
      width: 10px;
      height: 50px; }
  .sideBarUser .home {
    margin-top: 50px; }

.topBarUser {
  background-image: url("/public/assets/images/bgs/body_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 100;
  width: 100vw;
  height: 100px; }
  .topBarUser.scrolled {
    background-color: #ffffffdd;
    box-shadow: 0 0 10px #aaa; }
  .topBarUser .profileBtn {
    align-self: flex-start;
    margin-top: 1rem; }
  .topBarUser .leftBtns {
    justify-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: calc(2vw + 0.5rem);
    gap: 1rem; }
    .topBarUser .leftBtns .profileBtn {
      margin: 0; }
    .topBarUser .leftBtns > .iconBtn,
    .topBarUser .leftBtns > .profileBtn {
      height: 50px;
      min-width: 50px;
      border-radius: 0.5rem;
      background: linear-gradient(180deg, #f8fffa 0%, #e5f3ff 100%);
      font-size: 1.5rem;
      color: #4d4d4d; }
      .topBarUser .leftBtns > .iconBtn.connect,
      .topBarUser .leftBtns > .profileBtn.connect {
        font-size: 1rem;
        padding: 1rem; }
  .topBarUser .searchBar,
  .topBarUser .twoBtnSwitch {
    margin-right: 200px;
    z-index: 1; }
  .topBarUser .twoBtnSwitch.scrolled {
    transform: translate(-1rem, 100px); }
  .topBarUser .logoBuyWay {
    margin-right: 1rem;
    height: 50px;
    width: 50px;
    border-radius: 0.5rem;
    overflow: hidden;
    margin-right: calc(2vw + 0.5rem); }
  .topBarUser .searchBarAndBtnWrapper {
    align-items: center;
    height: 44px;
    overflow: hidden;
    border-radius: 2rem;
    justify-self: center; }
    .topBarUser .searchBarAndBtnWrapper .btn {
      min-width: 100px;
      border-radius: 0; }
    .topBarUser .searchBarAndBtnWrapper.search {
      width: 500px; }
      .topBarUser .searchBarAndBtnWrapper.search .searchBar {
        width: calc(100% - 100px);
        border-radius: 0; }
    .topBarUser .searchBarAndBtnWrapper.category {
      width: calc(90vw - 200px - 2rem);
      transform: translateX(calc(45vw - 0.5rem - 50% - 100px));
      border-radius: 0 2rem 2rem 0; }
  .topBarUser .profileBtn.notLogged ~ .searchBarAndBtnWrapper.category {
    width: calc(90vw - 200px - 10rem); }

.sideBarStore {
  background-color: #f7faff;
  display: flex;
  flex-direction: column;
  gap: 1rem; }
  .sideBarStore .sideIcon {
    height: 50px;
    border-radius: 0.5rem 0 0 0.5rem;
    overflow: hidden;
    justify-content: flex-start; }
    .sideBarStore .sideIcon .iconBtn__icon {
      padding-right: 2rem;
      margin-left: 1rem;
      font-size: 1.2rem; }
    .sideBarStore .sideIcon .iconBtn.active {
      width: 100%;
      font-weight: bold;
      color: #4038ad;
      background: #e5f3fe;
      background: linear-gradient(45deg, #f2f7fc 0%, #f1f8fc 100%); }
    .sideBarStore .sideIcon .iconBtn__active {
      position: absolute;
      background: linear-gradient(135deg, #3698ff 0%, #3e44e0 100%);
      border-radius: 3px 0 0 3px;
      width: 10px;
      height: 50px; }
  .sideBarStore .home {
    margin-top: 50px; }

.topBarStore {
  background-image: url("/public/assets/images/bgs/body_bg2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 100;
  width: 100vw;
  height: 100px; }
  .topBarStore .profileBtn {
    align-self: flex-start;
    margin-top: 1rem; }
  .topBarStore .leftBtns {
    justify-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: calc(2vw + 0.5rem);
    gap: 1rem; }
    .topBarStore .leftBtns .profileBtn {
      margin: 0; }
    .topBarStore .leftBtns > .iconBtn,
    .topBarStore .leftBtns > .profileBtn {
      height: 50px;
      min-width: 50px;
      border-radius: 0.5rem;
      background-color: #172573;
      opacity: 0.8;
      font-size: 1.5rem;
      color: white; }
      .topBarStore .leftBtns > .iconBtn.connect,
      .topBarStore .leftBtns > .profileBtn.connect {
        font-size: 1rem;
        padding: 1rem; }
  .topBarStore .filters {
    width: fit-content; }
    .topBarStore .filters .searchBar {
      margin-right: 200px;
      z-index: 1; }
  .topBarStore .logoBuyWay {
    margin-right: 1rem;
    height: 50px;
    width: 50px;
    border-radius: 0.5rem;
    overflow: hidden;
    margin-right: calc(2vw + 0.5rem); }
