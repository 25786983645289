@font-face {
  font-family: "uicons-brands";
  src: url("../webfonts/uicons-brands.eot#iefix") format("embedded-opentype"),
    url("../webfonts/uicons-brands.woff2") format("woff2"),
    url("../webfonts/uicons-brands.woff") format("woff");
}

i[class^="fi-brands-"]:before,
i[class*=" fi-brands-"]:before,
span[class^="fi-brands-"]:before,
span[class*="fi-brands-"]:before {
  font-family: uicons-brands !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi-brands-3m:before {
  content: "\f101";
}
.fi-brands-500px:before {
  content: "\f102";
}
.fi-brands-abbot-laboratories:before {
  content: "\f103";
}
.fi-brands-accusoft:before {
  content: "\f104";
}
.fi-brands-acrobat:before {
  content: "\f105";
}
.fi-brands-adobe:before {
  content: "\f106";
}
.fi-brands-aecom:before {
  content: "\f107";
}
.fi-brands-aero:before {
  content: "\f108";
}
.fi-brands-after-effects:before {
  content: "\f109";
}
.fi-brands-airbnb:before {
  content: "\f10a";
}
.fi-brands-algolia:before {
  content: "\f10b";
}
.fi-brands-amd:before {
  content: "\f10c";
}
.fi-brands-american-express:before {
  content: "\f10d";
}
.fi-brands-android:before {
  content: "\f10e";
}
.fi-brands-animate:before {
  content: "\f10f";
}
.fi-brands-app-store-ios:before {
  content: "\f110";
}
.fi-brands-apple-pay:before {
  content: "\f111";
}
.fi-brands-apple:before {
  content: "\f112";
}
.fi-brands-artstation:before {
  content: "\f113";
}
.fi-brands-astrazeneca:before {
  content: "\f114";
}
.fi-brands-asus:before {
  content: "\f115";
}
.fi-brands-atandt:before {
  content: "\f116";
}
.fi-brands-atlassian:before {
  content: "\f117";
}
.fi-brands-atom:before {
  content: "\f118";
}
.fi-brands-audition:before {
  content: "\f119";
}
.fi-brands-behance:before {
  content: "\f11a";
}
.fi-brands-bitcoin:before {
  content: "\f11b";
}
.fi-brands-blackberry:before {
  content: "\f11c";
}
.fi-brands-blogger:before {
  content: "\f11d";
}
.fi-brands-bluetooth:before {
  content: "\f11e";
}
.fi-brands-bootstrap:before {
  content: "\f11f";
}
.fi-brands-bridgestone:before {
  content: "\f120";
}
.fi-brands-burger-king:before {
  content: "\f121";
}
.fi-brands-c:before {
  content: "\f122";
}
.fi-brands-capture:before {
  content: "\f123";
}
.fi-brands-cc-apple-pay:before {
  content: "\f124";
}
.fi-brands-cc-diners-club:before {
  content: "\f125";
}
.fi-brands-cc-visa:before {
  content: "\f126";
}
.fi-brands-cc-amazon-pay:before {
  content: "\f127";
}
.fi-brands-centos:before {
  content: "\f128";
}
.fi-brands-character:before {
  content: "\f129";
}
.fi-brands-chromecast:before {
  content: "\f12a";
}
.fi-brands-cloudflare:before {
  content: "\f12b";
}
.fi-brands-confluence:before {
  content: "\f12c";
}
.fi-brands-creative-commons-by:before {
  content: "\f12d";
}
.fi-brands-creative-commons-nc-eu:before {
  content: "\f12e";
}
.fi-brands-creative-commons-nc-jp:before {
  content: "\f12f";
}
.fi-brands-creative-commons-nc:before {
  content: "\f130";
}
.fi-brands-creative-commons-nd:before {
  content: "\f131";
}
.fi-brands-creative-commons-pd-alt:before {
  content: "\f132";
}
.fi-brands-creative-commons-pd:before {
  content: "\f133";
}
.fi-brands-creative-commons-remix:before {
  content: "\f134";
}
.fi-brands-creative-commons-sa:before {
  content: "\f135";
}
.fi-brands-creative-commons-sampling-plus:before {
  content: "\f136";
}
.fi-brands-creative-commons-sampling:before {
  content: "\f137";
}
.fi-brands-creative-commons-share:before {
  content: "\f138";
}
.fi-brands-creative-commons-zero:before {
  content: "\f139";
}
.fi-brands-creative-commons:before {
  content: "\f13a";
}
.fi-brands-css3-alt:before {
  content: "\f13b";
}
.fi-brands-css3:before {
  content: "\f13c";
}
.fi-brands-dailymotion:before {
  content: "\f13d";
}
.fi-brands-deezer:before {
  content: "\f13e";
}
.fi-brands-delphi:before {
  content: "\f13f";
}
.fi-brands-dev:before {
  content: "\f140";
}
.fi-brands-devianart:before {
  content: "\f141";
}
.fi-brands-digg:before {
  content: "\f142";
}
.fi-brands-dimension:before {
  content: "\f143";
}
.fi-brands-discord:before {
  content: "\f144";
}
.fi-brands-docker:before {
  content: "\f145";
}
.fi-brands-dribbble:before {
  content: "\f146";
}
.fi-brands-dropbox:before {
  content: "\f147";
}
.fi-brands-drupal:before {
  content: "\f148";
}
.fi-brands-ebay:before {
  content: "\f149";
}
.fi-brands-elementor:before {
  content: "\f14a";
}
.fi-brands-ethereum:before {
  content: "\f14b";
}
.fi-brands-etsy:before {
  content: "\f14c";
}
.fi-brands-evernote:before {
  content: "\f14d";
}
.fi-brands-facebook-messenger:before {
  content: "\f14e";
}
.fi-brands-facebook:before {
  content: "\f14f";
}
.fi-brands-fedex:before {
  content: "\f150";
}
.fi-brands-figma:before {
  content: "\f151";
}
.fi-brands-firefox-browser:before {
  content: "\f152";
}
.fi-brands-firefox:before {
  content: "\f153";
}
.fi-brands-flickr:before {
  content: "\f154";
}
.fi-brands-flipboard:before {
  content: "\f155";
}
.fi-brands-fonts:before {
  content: "\f156";
}
.fi-brands-foursquare:before {
  content: "\f157";
}
.fi-brands-fresco:before {
  content: "\f158";
}
.fi-brands-github:before {
  content: "\f159";
}
.fi-brands-gitlab:before {
  content: "\f15a";
}
.fi-brands-goodreads:before {
  content: "\f15b";
}
.fi-brands-google:before {
  content: "\f15c";
}
.fi-brands-haskell:before {
  content: "\f15d";
}
.fi-brands-hbo:before {
  content: "\f15e";
}
.fi-brands-hotjar:before {
  content: "\f15f";
}
.fi-brands-html5:before {
  content: "\f160";
}
.fi-brands-huawei:before {
  content: "\f161";
}
.fi-brands-hubspot:before {
  content: "\f162";
}
.fi-brands-ibm:before {
  content: "\f163";
}
.fi-brands-imdb:before {
  content: "\f164";
}
.fi-brands-incopy:before {
  content: "\f165";
}
.fi-brands-indesign:before {
  content: "\f166";
}
.fi-brands-instagram:before {
  content: "\f167";
}
.fi-brands-intel:before {
  content: "\f168";
}
.fi-brands-invision:before {
  content: "\f169";
}
.fi-brands-itunes:before {
  content: "\f16a";
}
.fi-brands-janseen:before {
  content: "\f16b";
}
.fi-brands-java:before {
  content: "\f16c";
}
.fi-brands-jcb:before {
  content: "\f16d";
}
.fi-brands-jira:before {
  content: "\f16e";
}
.fi-brands-johnson-and-johnson:before {
  content: "\f16f";
}
.fi-brands-joomla:before {
  content: "\f170";
}
.fi-brands-js:before {
  content: "\f171";
}
.fi-brands-kickstarter:before {
  content: "\f172";
}
.fi-brands-line:before {
  content: "\f173";
}
.fi-brands-linkedin:before {
  content: "\f174";
}
.fi-brands-lisp:before {
  content: "\f175";
}
.fi-brands-mailchimp:before {
  content: "\f176";
}
.fi-brands-marriott-international:before {
  content: "\f177";
}
.fi-brands-mcdonalds:before {
  content: "\f178";
}
.fi-brands-media-encoder:before {
  content: "\f179";
}
.fi-brands-medium:before {
  content: "\f17a";
}
.fi-brands-meta:before {
  content: "\f17b";
}
.fi-brands-microsoft-explorer:before {
  content: "\f17c";
}
.fi-brands-mysql:before {
  content: "\f17d";
}
.fi-brands-napster:before {
  content: "\f17e";
}
.fi-brands-nestle:before {
  content: "\f17f";
}
.fi-brands-netflix:before {
  content: "\f180";
}
.fi-brands-node-js:before {
  content: "\f181";
}
.fi-brands-nvidia:before {
  content: "\f182";
}
.fi-brands-oracle:before {
  content: "\f183";
}
.fi-brands-patreon:before {
  content: "\f184";
}
.fi-brands-paypal:before {
  content: "\f185";
}
.fi-brands-pfizer:before {
  content: "\f186";
}
.fi-brands-photoshop-camera:before {
  content: "\f187";
}
.fi-brands-photoshop-express:before {
  content: "\f188";
}
.fi-brands-photoshop-lightroom-classic:before {
  content: "\f189";
}
.fi-brands-photoshop-lightroom:before {
  content: "\f18a";
}
.fi-brands-photoshop:before {
  content: "\f18b";
}
.fi-brands-php:before {
  content: "\f18c";
}
.fi-brands-pinterest:before {
  content: "\f18d";
}
.fi-brands-postgre:before {
  content: "\f18e";
}
.fi-brands-premiere-rush:before {
  content: "\f18f";
}
.fi-brands-premiere:before {
  content: "\f190";
}
.fi-brands-product-hunt:before {
  content: "\f191";
}
.fi-brands-python:before {
  content: "\f192";
}
.fi-brands-raspberry-pi:before {
  content: "\f193";
}
.fi-brands-reddit:before {
  content: "\f194";
}
.fi-brands-samsung:before {
  content: "\f195";
}
.fi-brands-sap:before {
  content: "\f196";
}
.fi-brands-sass:before {
  content: "\f197";
}
.fi-brands-shopify:before {
  content: "\f198";
}
.fi-brands-siemens:before {
  content: "\f199";
}
.fi-brands-sketch:before {
  content: "\f19a";
}
.fi-brands-skype:before {
  content: "\f19b";
}
.fi-brands-slack:before {
  content: "\f19c";
}
.fi-brands-snapchat:before {
  content: "\f19d";
}
.fi-brands-sony:before {
  content: "\f19e";
}
.fi-brands-soundcloud:before {
  content: "\f19f";
}
.fi-brands-spark:before {
  content: "\f1a0";
}
.fi-brands-spotify:before {
  content: "\f1a1";
}
.fi-brands-starbucks:before {
  content: "\f1a2";
}
.fi-brands-stock:before {
  content: "\f1a3";
}
.fi-brands-stripe:before {
  content: "\f1a4";
}
.fi-brands-substance-3d-designer:before {
  content: "\f1a5";
}
.fi-brands-substance-3d-painter:before {
  content: "\f1a6";
}
.fi-brands-substance-3d-sampler:before {
  content: "\f1a7";
}
.fi-brands-substance-3d-stager:before {
  content: "\f1a8";
}
.fi-brands-swift:before {
  content: "\f1a9";
}
.fi-brands-t-mobile:before {
  content: "\f1aa";
}
.fi-brands-telegram:before {
  content: "\f1ab";
}
.fi-brands-tencent:before {
  content: "\f1ac";
}
.fi-brands-the-home-depot:before {
  content: "\f1ad";
}
.fi-brands-tik-tok:before {
  content: "\f1ae";
}
.fi-brands-trello:before {
  content: "\f1af";
}
.fi-brands-tripadvisor:before {
  content: "\f1b0";
}
.fi-brands-tumblr:before {
  content: "\f1b1";
}
.fi-brands-twitch:before {
  content: "\f1b2";
}
.fi-brands-twitter:before {
  content: "\f1b3";
}
.fi-brands-typescript:before {
  content: "\f1b4";
}
.fi-brands-uber:before {
  content: "\f1b5";
}
.fi-brands-ubuntu:before {
  content: "\f1b6";
}
.fi-brands-unilever:before {
  content: "\f1b7";
}
.fi-brands-unity:before {
  content: "\f1b8";
}
.fi-brands-unsplash:before {
  content: "\f1b9";
}
.fi-brands-ups:before {
  content: "\f1ba";
}
.fi-brands-usaa:before {
  content: "\f1bb";
}
.fi-brands-verizon:before {
  content: "\f1bc";
}
.fi-brands-vimeo:before {
  content: "\f1bd";
}
.fi-brands-visa:before {
  content: "\f1be";
}
.fi-brands-visual-basic:before {
  content: "\f1bf";
}
.fi-brands-vk:before {
  content: "\f1c0";
}
.fi-brands-walmart:before {
  content: "\f1c1";
}
.fi-brands-whatsapp:before {
  content: "\f1c2";
}
.fi-brands-wikipedia:before {
  content: "\f1c3";
}
.fi-brands-windows:before {
  content: "\f1c4";
}
.fi-brands-wix:before {
  content: "\f1c5";
}
.fi-brands-wordpress:before {
  content: "\f1c6";
}
.fi-brands-xd:before {
  content: "\f1c7";
}
.fi-brands-xing:before {
  content: "\f1c8";
}
.fi-brands-yahoo:before {
  content: "\f1c9";
}
.fi-brands-yandex:before {
  content: "\f1ca";
}
.fi-brands-yelp:before {
  content: "\f1cb";
}
.fi-brands-youtube:before {
  content: "\f1cc";
}
.fi-brands-zoom:before {
  content: "\f1cd";
}
