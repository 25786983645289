@font-face {
  font-family: "uicons-regular-straight";
  src: url("../webfonts/uicons-regular-straight.eot#iefix")
      format("embedded-opentype"),
    url("../webfonts/uicons-regular-straight.woff2") format("woff2"),
    url("../webfonts/uicons-regular-straight.woff") format("woff");
}

i[class^="fi-rs-"]:before,
i[class*=" fi-rs-"]:before,
span[class^="fi-rs-"]:before,
span[class*="fi-rs-"]:before {
  font-family: uicons-regular-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi-rs-add:before {
  content: "\f101";
}
.fi-rs-address-book:before {
  content: "\f102";
}
.fi-rs-alarm-clock:before {
  content: "\f103";
}
.fi-rs-align-center:before {
  content: "\f104";
}
.fi-rs-align-justify:before {
  content: "\f105";
}
.fi-rs-align-left:before {
  content: "\f106";
}
.fi-rs-align-right:before {
  content: "\f107";
}
.fi-rs-ambulance:before {
  content: "\f108";
}
.fi-rs-angle-double-left:before {
  content: "\f109";
}
.fi-rs-angle-double-right:before {
  content: "\f10a";
}
.fi-rs-angle-double-small-left:before {
  content: "\f10b";
}
.fi-rs-angle-double-small-right:before {
  content: "\f10c";
}
.fi-rs-angle-down:before {
  content: "\f10d";
}
.fi-rs-angle-left:before {
  content: "\f10e";
}
.fi-rs-angle-right:before {
  content: "\f10f";
}
.fi-rs-angle-small-down:before {
  content: "\f110";
}
.fi-rs-angle-small-left:before {
  content: "\f111";
}
.fi-rs-angle-small-right:before {
  content: "\f112";
}
.fi-rs-angle-small-up:before {
  content: "\f113";
}
.fi-rs-angle-up:before {
  content: "\f114";
}
.fi-rs-apple:before {
  content: "\f115";
}
.fi-rs-apps-add:before {
  content: "\f116";
}
.fi-rs-apps-delete:before {
  content: "\f117";
}
.fi-rs-apps-sort:before {
  content: "\f118";
}
.fi-rs-apps:before {
  content: "\f119";
}
.fi-rs-archive:before {
  content: "\f11a";
}
.fi-rs-arrow-down:before {
  content: "\f11b";
}
.fi-rs-arrow-from-bottom:before {
  content: "\f11c";
}
.fi-rs-arrow-left:before {
  content: "\f11d";
}
.fi-rs-arrow-right:before {
  content: "\f11e";
}
.fi-rs-arrow-small-down:before {
  content: "\f11f";
}
.fi-rs-arrow-small-left:before {
  content: "\f120";
}
.fi-rs-arrow-small-right:before {
  content: "\f121";
}
.fi-rs-arrow-small-up:before {
  content: "\f122";
}
.fi-rs-arrow-up:before {
  content: "\f123";
}
.fi-rs-asterik:before {
  content: "\f124";
}
.fi-rs-at:before {
  content: "\f125";
}
.fi-rs-backpack:before {
  content: "\f126";
}
.fi-rs-badge:before {
  content: "\f127";
}
.fi-rs-balloons:before {
  content: "\f128";
}
.fi-rs-ban:before {
  content: "\f129";
}
.fi-rs-band-aid:before {
  content: "\f12a";
}
.fi-rs-bank:before {
  content: "\f12b";
}
.fi-rs-barber-shop:before {
  content: "\f12c";
}
.fi-rs-baseball:before {
  content: "\f12d";
}
.fi-rs-basketball:before {
  content: "\f12e";
}
.fi-rs-bed:before {
  content: "\f12f";
}
.fi-rs-beer:before {
  content: "\f130";
}
.fi-rs-bell-ring:before {
  content: "\f131";
}
.fi-rs-bell-school:before {
  content: "\f132";
}
.fi-rs-bell:before {
  content: "\f133";
}
.fi-rs-bike:before {
  content: "\f134";
}
.fi-rs-billiard:before {
  content: "\f135";
}
.fi-rs-bold:before {
  content: "\f136";
}
.fi-rs-book-alt:before {
  content: "\f137";
}
.fi-rs-book:before {
  content: "\f138";
}
.fi-rs-bookmark:before {
  content: "\f139";
}
.fi-rs-bowling:before {
  content: "\f13a";
}
.fi-rs-box-alt:before {
  content: "\f13b";
}
.fi-rs-box:before {
  content: "\f13c";
}
.fi-rs-bread-slice:before {
  content: "\f13d";
}
.fi-rs-briefcase:before {
  content: "\f13e";
}
.fi-rs-broom:before {
  content: "\f13f";
}
.fi-rs-browser:before {
  content: "\f140";
}
.fi-rs-brush:before {
  content: "\f141";
}
.fi-rs-bug:before {
  content: "\f142";
}
.fi-rs-building:before {
  content: "\f143";
}
.fi-rs-bulb:before {
  content: "\f144";
}
.fi-rs-butterfly:before {
  content: "\f145";
}
.fi-rs-cake-birthday:before {
  content: "\f146";
}
.fi-rs-cake-wedding:before {
  content: "\f147";
}
.fi-rs-calculator:before {
  content: "\f148";
}
.fi-rs-calendar:before {
  content: "\f149";
}
.fi-rs-call-history:before {
  content: "\f14a";
}
.fi-rs-call-incoming:before {
  content: "\f14b";
}
.fi-rs-call-missed:before {
  content: "\f14c";
}
.fi-rs-call-outgoing:before {
  content: "\f14d";
}
.fi-rs-camera:before {
  content: "\f14e";
}
.fi-rs-camping:before {
  content: "\f14f";
}
.fi-rs-car:before {
  content: "\f150";
}
.fi-rs-caret-down:before {
  content: "\f151";
}
.fi-rs-caret-left:before {
  content: "\f152";
}
.fi-rs-caret-right:before {
  content: "\f153";
}
.fi-rs-caret-up:before {
  content: "\f154";
}
.fi-rs-carrot:before {
  content: "\f155";
}
.fi-rs-chart-connected:before {
  content: "\f156";
}
.fi-rs-chart-histogram:before {
  content: "\f157";
}
.fi-rs-chart-network:before {
  content: "\f158";
}
.fi-rs-chart-pie-alt:before {
  content: "\f159";
}
.fi-rs-chart-pie:before {
  content: "\f15a";
}
.fi-rs-chart-pyramid:before {
  content: "\f15b";
}
.fi-rs-chart-set-theory:before {
  content: "\f15c";
}
.fi-rs-chart-tree:before {
  content: "\f15d";
}
.fi-rs-chat-arrow-down:before {
  content: "\f15e";
}
.fi-rs-chat-arrow-grow:before {
  content: "\f15f";
}
.fi-rs-check:before {
  content: "\f160";
}
.fi-rs-checkbox:before {
  content: "\f161";
}
.fi-rs-cheese:before {
  content: "\f162";
}
.fi-rs-chess-piece:before {
  content: "\f163";
}
.fi-rs-child-head:before {
  content: "\f164";
}
.fi-rs-circle-small:before {
  content: "\f165";
}
.fi-rs-circle:before {
  content: "\f166";
}
.fi-rs-clip:before {
  content: "\f167";
}
.fi-rs-clock:before {
  content: "\f168";
}
.fi-rs-cloud-check:before {
  content: "\f169";
}
.fi-rs-cloud-disabled:before {
  content: "\f16a";
}
.fi-rs-cloud-download:before {
  content: "\f16b";
}
.fi-rs-cloud-share:before {
  content: "\f16c";
}
.fi-rs-cloud-upload:before {
  content: "\f16d";
}
.fi-rs-cloud:before {
  content: "\f16e";
}
.fi-rs-clouds:before {
  content: "\f16f";
}
.fi-rs-cocktail:before {
  content: "\f170";
}
.fi-rs-coffee:before {
  content: "\f171";
}
.fi-rs-comment-alt:before {
  content: "\f172";
}
.fi-rs-comment-check:before {
  content: "\f173";
}
.fi-rs-comment-heart:before {
  content: "\f174";
}
.fi-rs-comment-info:before {
  content: "\f175";
}
.fi-rs-comment-user:before {
  content: "\f176";
}
.fi-rs-comment:before {
  content: "\f177";
}
.fi-rs-comments:before {
  content: "\f178";
}
.fi-rs-compress-alt:before {
  content: "\f179";
}
.fi-rs-compress:before {
  content: "\f17a";
}
.fi-rs-computer:before {
  content: "\f17b";
}
.fi-rs-confetti:before {
  content: "\f17c";
}
.fi-rs-cookie:before {
  content: "\f17d";
}
.fi-rs-copy-alt:before {
  content: "\f17e";
}
.fi-rs-copy:before {
  content: "\f17f";
}
.fi-rs-copyright:before {
  content: "\f180";
}
.fi-rs-cow:before {
  content: "\f181";
}
.fi-rs-cream:before {
  content: "\f182";
}
.fi-rs-credit-card:before {
  content: "\f183";
}
.fi-rs-croissant:before {
  content: "\f184";
}
.fi-rs-cross-circle:before {
  content: "\f185";
}
.fi-rs-cross-small:before {
  content: "\f186";
}
.fi-rs-cross:before {
  content: "\f187";
}
.fi-rs-crown:before {
  content: "\f188";
}
.fi-rs-cube:before {
  content: "\f189";
}
.fi-rs-cupcake:before {
  content: "\f18a";
}
.fi-rs-cursor-finger:before {
  content: "\f18b";
}
.fi-rs-cursor-plus:before {
  content: "\f18c";
}
.fi-rs-cursor-text-alt:before {
  content: "\f18d";
}
.fi-rs-cursor-text:before {
  content: "\f18e";
}
.fi-rs-cursor:before {
  content: "\f18f";
}
.fi-rs-dart:before {
  content: "\f190";
}
.fi-rs-dashboard:before {
  content: "\f191";
}
.fi-rs-data-transfer:before {
  content: "\f192";
}
.fi-rs-database:before {
  content: "\f193";
}
.fi-rs-delete:before {
  content: "\f194";
}
.fi-rs-diamond:before {
  content: "\f195";
}
.fi-rs-dice:before {
  content: "\f196";
}
.fi-rs-diploma:before {
  content: "\f197";
}
.fi-rs-disco-ball:before {
  content: "\f198";
}
.fi-rs-disk:before {
  content: "\f199";
}
.fi-rs-doctor:before {
  content: "\f19a";
}
.fi-rs-document-signed:before {
  content: "\f19b";
}
.fi-rs-document:before {
  content: "\f19c";
}
.fi-rs-dollar:before {
  content: "\f19d";
}
.fi-rs-download:before {
  content: "\f19e";
}
.fi-rs-drink-alt:before {
  content: "\f19f";
}
.fi-rs-drumstick:before {
  content: "\f1a0";
}
.fi-rs-duplicate:before {
  content: "\f1a1";
}
.fi-rs-e-learning:before {
  content: "\f1a2";
}
.fi-rs-earnings:before {
  content: "\f1a3";
}
.fi-rs-edit-alt:before {
  content: "\f1a4";
}
.fi-rs-edit:before {
  content: "\f1a5";
}
.fi-rs-envelope-ban:before {
  content: "\f1a6";
}
.fi-rs-envelope-download:before {
  content: "\f1a7";
}
.fi-rs-envelope-marker:before {
  content: "\f1a8";
}
.fi-rs-envelope-open:before {
  content: "\f1a9";
}
.fi-rs-envelope-plus:before {
  content: "\f1aa";
}
.fi-rs-envelope:before {
  content: "\f1ab";
}
.fi-rs-euro:before {
  content: "\f1ac";
}
.fi-rs-exclamation:before {
  content: "\f1ad";
}
.fi-rs-expand:before {
  content: "\f1ae";
}
.fi-rs-eye-crossed:before {
  content: "\f1af";
}
.fi-rs-eye-dropper:before {
  content: "\f1b0";
}
.fi-rs-eye:before {
  content: "\f1b1";
}
.fi-rs-feather:before {
  content: "\f1b2";
}
.fi-rs-ferris-wheel:before {
  content: "\f1b3";
}
.fi-rs-file-add:before {
  content: "\f1b4";
}
.fi-rs-file-ai:before {
  content: "\f1b5";
}
.fi-rs-file-check:before {
  content: "\f1b6";
}
.fi-rs-file-delete:before {
  content: "\f1b7";
}
.fi-rs-file-eps:before {
  content: "\f1b8";
}
.fi-rs-file-gif:before {
  content: "\f1b9";
}
.fi-rs-file-music:before {
  content: "\f1ba";
}
.fi-rs-file-psd:before {
  content: "\f1bb";
}
.fi-rs-file:before {
  content: "\f1bc";
}
.fi-rs-fill:before {
  content: "\f1bd";
}
.fi-rs-film:before {
  content: "\f1be";
}
.fi-rs-filter:before {
  content: "\f1bf";
}
.fi-rs-fingerprint:before {
  content: "\f1c0";
}
.fi-rs-fish:before {
  content: "\f1c1";
}
.fi-rs-flag:before {
  content: "\f1c2";
}
.fi-rs-flame:before {
  content: "\f1c3";
}
.fi-rs-flip-horizontal:before {
  content: "\f1c4";
}
.fi-rs-flower-bouquet:before {
  content: "\f1c5";
}
.fi-rs-flower-tulip:before {
  content: "\f1c6";
}
.fi-rs-flower:before {
  content: "\f1c7";
}
.fi-rs-folder-add:before {
  content: "\f1c8";
}
.fi-rs-folder:before {
  content: "\f1c9";
}
.fi-rs-following:before {
  content: "\f1ca";
}
.fi-rs-football:before {
  content: "\f1cb";
}
.fi-rs-form:before {
  content: "\f1cc";
}
.fi-rs-forward:before {
  content: "\f1cd";
}
.fi-rs-fox:before {
  content: "\f1ce";
}
.fi-rs-frown:before {
  content: "\f1cf";
}
.fi-rs-ftp:before {
  content: "\f1d0";
}
.fi-rs-gallery:before {
  content: "\f1d1";
}
.fi-rs-gamepad:before {
  content: "\f1d2";
}
.fi-rs-gas-pump:before {
  content: "\f1d3";
}
.fi-rs-gem:before {
  content: "\f1d4";
}
.fi-rs-gift:before {
  content: "\f1d5";
}
.fi-rs-glass-cheers:before {
  content: "\f1d6";
}
.fi-rs-glasses:before {
  content: "\f1d7";
}
.fi-rs-globe-alt:before {
  content: "\f1d8";
}
.fi-rs-globe:before {
  content: "\f1d9";
}
.fi-rs-golf:before {
  content: "\f1da";
}
.fi-rs-graduation-cap:before {
  content: "\f1db";
}
.fi-rs-graphic-tablet:before {
  content: "\f1dc";
}
.fi-rs-grid-alt:before {
  content: "\f1dd";
}
.fi-rs-grid:before {
  content: "\f1de";
}
.fi-rs-guitar:before {
  content: "\f1df";
}
.fi-rs-gym:before {
  content: "\f1e0";
}
.fi-rs-hamburger:before {
  content: "\f1e1";
}
.fi-rs-hand-holding-heart:before {
  content: "\f1e2";
}
.fi-rs-hastag:before {
  content: "\f1e3";
}
.fi-rs-hat-birthday:before {
  content: "\f1e4";
}
.fi-rs-head-side-thinking:before {
  content: "\f1e5";
}
.fi-rs-headphones:before {
  content: "\f1e6";
}
.fi-rs-headset:before {
  content: "\f1e7";
}
.fi-rs-heart-arrow:before {
  content: "\f1e8";
}
.fi-rs-heart:before {
  content: "\f1e9";
}
.fi-rs-home-location-alt:before {
  content: "\f1ea";
}
.fi-rs-home-location:before {
  content: "\f1eb";
}
.fi-rs-home:before {
  content: "\f1ec";
}
.fi-rs-hourglass-end:before {
  content: "\f1ed";
}
.fi-rs-hourglass:before {
  content: "\f1ee";
}
.fi-rs-ice-cream:before {
  content: "\f1ef";
}
.fi-rs-ice-skate:before {
  content: "\f1f0";
}
.fi-rs-id-badge:before {
  content: "\f1f1";
}
.fi-rs-inbox:before {
  content: "\f1f2";
}
.fi-rs-incognito:before {
  content: "\f1f3";
}
.fi-rs-indent:before {
  content: "\f1f4";
}
.fi-rs-infinity:before {
  content: "\f1f5";
}
.fi-rs-info:before {
  content: "\f1f6";
}
.fi-rs-interactive:before {
  content: "\f1f7";
}
.fi-rs-interlining:before {
  content: "\f1f8";
}
.fi-rs-interrogation:before {
  content: "\f1f9";
}
.fi-rs-italic:before {
  content: "\f1fa";
}
.fi-rs-jpg:before {
  content: "\f1fb";
}
.fi-rs-key:before {
  content: "\f1fc";
}
.fi-rs-keyboard:before {
  content: "\f1fd";
}
.fi-rs-kite:before {
  content: "\f1fe";
}
.fi-rs-label:before {
  content: "\f1ff";
}
.fi-rs-laptop:before {
  content: "\f200";
}
.fi-rs-lasso:before {
  content: "\f201";
}
.fi-rs-laugh:before {
  content: "\f202";
}
.fi-rs-layers:before {
  content: "\f203";
}
.fi-rs-layout-fluid:before {
  content: "\f204";
}
.fi-rs-leaf:before {
  content: "\f205";
}
.fi-rs-letter-case:before {
  content: "\f206";
}
.fi-rs-life-ring:before {
  content: "\f207";
}
.fi-rs-line-width:before {
  content: "\f208";
}
.fi-rs-link:before {
  content: "\f209";
}
.fi-rs-lipstick:before {
  content: "\f20a";
}
.fi-rs-list-check:before {
  content: "\f20b";
}
.fi-rs-list:before {
  content: "\f20c";
}
.fi-rs-location-alt:before {
  content: "\f20d";
}
.fi-rs-lock-alt:before {
  content: "\f20e";
}
.fi-rs-lock:before {
  content: "\f20f";
}
.fi-rs-luggage-rolling:before {
  content: "\f210";
}
.fi-rs-magic-wand:before {
  content: "\f211";
}
.fi-rs-makeup-brush:before {
  content: "\f212";
}
.fi-rs-man-head:before {
  content: "\f213";
}
.fi-rs-map-marker-cross:before {
  content: "\f214";
}
.fi-rs-map-marker-home:before {
  content: "\f215";
}
.fi-rs-map-marker-minus:before {
  content: "\f216";
}
.fi-rs-map-marker-plus:before {
  content: "\f217";
}
.fi-rs-map-marker:before {
  content: "\f218";
}
.fi-rs-map:before {
  content: "\f219";
}
.fi-rs-marker-time:before {
  content: "\f21a";
}
.fi-rs-marker:before {
  content: "\f21b";
}
.fi-rs-mars-double:before {
  content: "\f21c";
}
.fi-rs-mars:before {
  content: "\f21d";
}
.fi-rs-mask-carnival:before {
  content: "\f21e";
}
.fi-rs-medicine:before {
  content: "\f21f";
}
.fi-rs-megaphone:before {
  content: "\f220";
}
.fi-rs-meh:before {
  content: "\f221";
}
.fi-rs-menu-burger:before {
  content: "\f222";
}
.fi-rs-menu-dots-vertical:before {
  content: "\f223";
}
.fi-rs-menu-dots:before {
  content: "\f224";
}
.fi-rs-microphone-alt:before {
  content: "\f225";
}
.fi-rs-microphone:before {
  content: "\f226";
}
.fi-rs-minus-small:before {
  content: "\f227";
}
.fi-rs-minus:before {
  content: "\f228";
}
.fi-rs-mobile:before {
  content: "\f229";
}
.fi-rs-mode-landscape:before {
  content: "\f22a";
}
.fi-rs-mode-portrait:before {
  content: "\f22b";
}
.fi-rs-money:before {
  content: "\f22c";
}
.fi-rs-moon:before {
  content: "\f22d";
}
.fi-rs-mountains:before {
  content: "\f22e";
}
.fi-rs-mouse:before {
  content: "\f22f";
}
.fi-rs-mug-alt:before {
  content: "\f230";
}
.fi-rs-music-alt:before {
  content: "\f231";
}
.fi-rs-music:before {
  content: "\f232";
}
.fi-rs-navigation:before {
  content: "\f233";
}
.fi-rs-network-cloud:before {
  content: "\f234";
}
.fi-rs-network:before {
  content: "\f235";
}
.fi-rs-notebook:before {
  content: "\f236";
}
.fi-rs-opacity:before {
  content: "\f237";
}
.fi-rs-package:before {
  content: "\f238";
}
.fi-rs-paint-brush:before {
  content: "\f239";
}
.fi-rs-palette:before {
  content: "\f23a";
}
.fi-rs-paper-plane:before {
  content: "\f23b";
}
.fi-rs-password:before {
  content: "\f23c";
}
.fi-rs-pause:before {
  content: "\f23d";
}
.fi-rs-paw:before {
  content: "\f23e";
}
.fi-rs-pencil:before {
  content: "\f23f";
}
.fi-rs-pharmacy:before {
  content: "\f240";
}
.fi-rs-phone-call:before {
  content: "\f241";
}
.fi-rs-phone-cross:before {
  content: "\f242";
}
.fi-rs-phone-pause:before {
  content: "\f243";
}
.fi-rs-phone-slash:before {
  content: "\f244";
}
.fi-rs-physics:before {
  content: "\f245";
}
.fi-rs-picture:before {
  content: "\f246";
}
.fi-rs-ping-pong:before {
  content: "\f247";
}
.fi-rs-pizza-slice:before {
  content: "\f248";
}
.fi-rs-plane:before {
  content: "\f249";
}
.fi-rs-play-alt:before {
  content: "\f24a";
}
.fi-rs-play:before {
  content: "\f24b";
}
.fi-rs-playing-cards:before {
  content: "\f24c";
}
.fi-rs-plus-small:before {
  content: "\f24d";
}
.fi-rs-plus:before {
  content: "\f24e";
}
.fi-rs-poker-chip:before {
  content: "\f24f";
}
.fi-rs-portrait:before {
  content: "\f250";
}
.fi-rs-pound:before {
  content: "\f251";
}
.fi-rs-power:before {
  content: "\f252";
}
.fi-rs-presentation:before {
  content: "\f253";
}
.fi-rs-print:before {
  content: "\f254";
}
.fi-rs-protractor:before {
  content: "\f255";
}
.fi-rs-pulse:before {
  content: "\f256";
}
.fi-rs-pyramid:before {
  content: "\f257";
}
.fi-rs-quote-right:before {
  content: "\f258";
}
.fi-rs-rainbow:before {
  content: "\f259";
}
.fi-rs-raindrops:before {
  content: "\f25a";
}
.fi-rs-rec:before {
  content: "\f25b";
}
.fi-rs-receipt:before {
  content: "\f25c";
}
.fi-rs-record-vinyl:before {
  content: "\f25d";
}
.fi-rs-rectabgle-vertical:before {
  content: "\f25e";
}
.fi-rs-rectangle-horizontal:before {
  content: "\f25f";
}
.fi-rs-rectangle-panoramic:before {
  content: "\f260";
}
.fi-rs-recycle:before {
  content: "\f261";
}
.fi-rs-redo-alt:before {
  content: "\f262";
}
.fi-rs-redo:before {
  content: "\f263";
}
.fi-rs-reflect:before {
  content: "\f264";
}
.fi-rs-refresh:before {
  content: "\f265";
}
.fi-rs-resize:before {
  content: "\f266";
}
.fi-rs-resources:before {
  content: "\f267";
}
.fi-rs-rewind:before {
  content: "\f268";
}
.fi-rs-rhombus:before {
  content: "\f269";
}
.fi-rs-rings-wedding:before {
  content: "\f26a";
}
.fi-rs-road:before {
  content: "\f26b";
}
.fi-rs-rocket:before {
  content: "\f26c";
}
.fi-rs-room-service:before {
  content: "\f26d";
}
.fi-rs-rotate-right:before {
  content: "\f26e";
}
.fi-rs-rugby:before {
  content: "\f26f";
}
.fi-rs-sad:before {
  content: "\f270";
}
.fi-rs-salad:before {
  content: "\f271";
}
.fi-rs-scale:before {
  content: "\f272";
}
.fi-rs-school-bus:before {
  content: "\f273";
}
.fi-rs-school:before {
  content: "\f274";
}
.fi-rs-scissors:before {
  content: "\f275";
}
.fi-rs-screen:before {
  content: "\f276";
}
.fi-rs-search-alt:before {
  content: "\f277";
}
.fi-rs-search-heart:before {
  content: "\f278";
}
.fi-rs-search:before {
  content: "\f279";
}
.fi-rs-settings-sliders:before {
  content: "\f27a";
}
.fi-rs-settings:before {
  content: "\f27b";
}
.fi-rs-share:before {
  content: "\f27c";
}
.fi-rs-shield-check:before {
  content: "\f27d";
}
.fi-rs-shield-exclamation:before {
  content: "\f27e";
}
.fi-rs-shield-interrogation:before {
  content: "\f27f";
}
.fi-rs-shield-plus:before {
  content: "\f280";
}
.fi-rs-shield:before {
  content: "\f281";
}
.fi-rs-ship-side:before {
  content: "\f282";
}
.fi-rs-ship:before {
  content: "\f283";
}
.fi-rs-shop:before {
  content: "\f284";
}
.fi-rs-shopping-bag-add:before {
  content: "\f285";
}
.fi-rs-shopping-bag:before {
  content: "\f286";
}
.fi-rs-shopping-cart-add:before {
  content: "\f287";
}
.fi-rs-shopping-cart-check:before {
  content: "\f288";
}
.fi-rs-shopping-cart:before {
  content: "\f289";
}
.fi-rs-shuffle:before {
  content: "\f28a";
}
.fi-rs-sign-in-alt:before {
  content: "\f28b";
}
.fi-rs-sign-in:before {
  content: "\f28c";
}
.fi-rs-sign-out-alt:before {
  content: "\f28d";
}
.fi-rs-sign-out:before {
  content: "\f28e";
}
.fi-rs-signal-alt-1:before {
  content: "\f28f";
}
.fi-rs-signal-alt-2:before {
  content: "\f290";
}
.fi-rs-signal-alt:before {
  content: "\f291";
}
.fi-rs-skateboard:before {
  content: "\f292";
}
.fi-rs-smartphone:before {
  content: "\f293";
}
.fi-rs-smile-wink:before {
  content: "\f294";
}
.fi-rs-smile:before {
  content: "\f295";
}
.fi-rs-snowflake:before {
  content: "\f296";
}
.fi-rs-soap:before {
  content: "\f297";
}
.fi-rs-soup:before {
  content: "\f298";
}
.fi-rs-spa:before {
  content: "\f299";
}
.fi-rs-speaker:before {
  content: "\f29a";
}
.fi-rs-sphere:before {
  content: "\f29b";
}
.fi-rs-spinner-alt:before {
  content: "\f29c";
}
.fi-rs-spinner:before {
  content: "\f29d";
}
.fi-rs-square-root:before {
  content: "\f29e";
}
.fi-rs-square:before {
  content: "\f29f";
}
.fi-rs-star-octogram:before {
  content: "\f2a0";
}
.fi-rs-star:before {
  content: "\f2a1";
}
.fi-rs-stats:before {
  content: "\f2a2";
}
.fi-rs-stethoscope:before {
  content: "\f2a3";
}
.fi-rs-sticker:before {
  content: "\f2a4";
}
.fi-rs-stop:before {
  content: "\f2a5";
}
.fi-rs-stopwatch:before {
  content: "\f2a6";
}
.fi-rs-subtitles:before {
  content: "\f2a7";
}
.fi-rs-sun:before {
  content: "\f2a8";
}
.fi-rs-sunrise:before {
  content: "\f2a9";
}
.fi-rs-surfing:before {
  content: "\f2aa";
}
.fi-rs-sword:before {
  content: "\f2ab";
}
.fi-rs-syringe:before {
  content: "\f2ac";
}
.fi-rs-tablet:before {
  content: "\f2ad";
}
.fi-rs-target:before {
  content: "\f2ae";
}
.fi-rs-taxi:before {
  content: "\f2af";
}
.fi-rs-tennis:before {
  content: "\f2b0";
}
.fi-rs-terrace:before {
  content: "\f2b1";
}
.fi-rs-test-tube:before {
  content: "\f2b2";
}
.fi-rs-test:before {
  content: "\f2b3";
}
.fi-rs-text-check:before {
  content: "\f2b4";
}
.fi-rs-text:before {
  content: "\f2b5";
}
.fi-rs-thermometer-half:before {
  content: "\f2b6";
}
.fi-rs-thumbs-down:before {
  content: "\f2b7";
}
.fi-rs-thumbs-up:before {
  content: "\f2b8";
}
.fi-rs-thumbtack:before {
  content: "\f2b9";
}
.fi-rs-ticket:before {
  content: "\f2ba";
}
.fi-rs-time-add:before {
  content: "\f2bb";
}
.fi-rs-time-check:before {
  content: "\f2bc";
}
.fi-rs-time-delete:before {
  content: "\f2bd";
}
.fi-rs-time-fast:before {
  content: "\f2be";
}
.fi-rs-time-forward-sixty:before {
  content: "\f2bf";
}
.fi-rs-time-forward-ten:before {
  content: "\f2c0";
}
.fi-rs-time-forward:before {
  content: "\f2c1";
}
.fi-rs-time-half-past:before {
  content: "\f2c2";
}
.fi-rs-time-oclock:before {
  content: "\f2c3";
}
.fi-rs-time-past:before {
  content: "\f2c4";
}
.fi-rs-time-quarter-past:before {
  content: "\f2c5";
}
.fi-rs-time-quarter-to:before {
  content: "\f2c6";
}
.fi-rs-time-twenty-four:before {
  content: "\f2c7";
}
.fi-rs-tool-crop:before {
  content: "\f2c8";
}
.fi-rs-tool-marquee:before {
  content: "\f2c9";
}
.fi-rs-tooth:before {
  content: "\f2ca";
}
.fi-rs-tornado:before {
  content: "\f2cb";
}
.fi-rs-train-side:before {
  content: "\f2cc";
}
.fi-rs-train:before {
  content: "\f2cd";
}
.fi-rs-transform:before {
  content: "\f2ce";
}
.fi-rs-trash:before {
  content: "\f2cf";
}
.fi-rs-treatment:before {
  content: "\f2d0";
}
.fi-rs-tree-christmas:before {
  content: "\f2d1";
}
.fi-rs-tree:before {
  content: "\f2d2";
}
.fi-rs-triangle:before {
  content: "\f2d3";
}
.fi-rs-trophy:before {
  content: "\f2d4";
}
.fi-rs-truck-side:before {
  content: "\f2d5";
}
.fi-rs-umbrella:before {
  content: "\f2d6";
}
.fi-rs-underline:before {
  content: "\f2d7";
}
.fi-rs-undo-alt:before {
  content: "\f2d8";
}
.fi-rs-undo:before {
  content: "\f2d9";
}
.fi-rs-unlock:before {
  content: "\f2da";
}
.fi-rs-upload:before {
  content: "\f2db";
}
.fi-rs-usb-pendrive:before {
  content: "\f2dc";
}
.fi-rs-user-add:before {
  content: "\f2dd";
}
.fi-rs-user-delete:before {
  content: "\f2de";
}
.fi-rs-user-remove:before {
  content: "\f2df";
}
.fi-rs-user-time:before {
  content: "\f2e0";
}
.fi-rs-user:before {
  content: "\f2e1";
}
.fi-rs-utensils:before {
  content: "\f2e2";
}
.fi-rs-vector-alt:before {
  content: "\f2e3";
}
.fi-rs-vector:before {
  content: "\f2e4";
}
.fi-rs-venus-double:before {
  content: "\f2e5";
}
.fi-rs-venus-mars:before {
  content: "\f2e6";
}
.fi-rs-venus:before {
  content: "\f2e7";
}
.fi-rs-video-camera:before {
  content: "\f2e8";
}
.fi-rs-volleyball:before {
  content: "\f2e9";
}
.fi-rs-volume:before {
  content: "\f2ea";
}
.fi-rs-wheelchair:before {
  content: "\f2eb";
}
.fi-rs-wifi-alt:before {
  content: "\f2ec";
}
.fi-rs-wind:before {
  content: "\f2ed";
}
.fi-rs-woman-head:before {
  content: "\f2ee";
}
.fi-rs-world:before {
  content: "\f2ef";
}
.fi-rs-yen:before {
  content: "\f2f0";
}
.fi-rs-zoom-in:before {
  content: "\f2f1";
}
.fi-rs-zoom-out:before {
  content: "\f2f2";
}
